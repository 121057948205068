import React, { useState, useEffect } from 'react';
import { UCard } from '../CustomComponents/UCard';
import { UButton } from "../CustomComponents/UButton";
import { getFromLocalStorage, saveToLocalStorage } from "../utils/localStorageHandler";
import { useLanguage } from "../contexts/LanguageContext";
import { UInput } from "../CustomComponents/UInput";
import { updateTermsAndConditions, getTermsAndConditions } from "../server/terms-and-conditions/[...].handler";
import toastr from "toastr";
import { ULoading } from "../CustomComponents/ULoading";
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';

const translations = {
    en: {
        termsAndConditions: "Terms and Conditions",
        addSection: "Add Section",
        editSection: "Edit Section",
        saveChanges: "Save Changes",
        cancel: "Cancel",
        deleteSection: "Delete Section",
        sectionTitle: "Section Title",
        sectionContent: "Section Content",
        fillPreviousSection: "Please fill in the title and content of the previous section before adding a new one.",
        confirmDelete: "Are you sure you want to delete this section?",
        moveUp: "Move Up",
        moveDown: "Move Down",
        sectionSaved: "Section saved successfully",
        sectionDeleted: "Section deleted successfully",
        changesSaved: "All changes saved successfully",
        markdownSupported: "Markdown is supported for formatting.",
        loading: "Loading...",
        error: "Error loading Terms and Conditions. Please try again later.",
    },
    pt: {
        termsAndConditions: "Termos e Condições",
        addSection: "Adicionar Seção",
        editSection: "Editar Seção",
        saveChanges: "Salvar Alterações",
        cancel: "Cancelar",
        deleteSection: "Deletar Seção",
        sectionTitle: "Título da Seção",
        sectionContent: "Conteúdo da Seção",
        fillPreviousSection: "Por favor, preencha o título e o conteúdo da seção anterior antes de adicionar uma nova.",
        confirmDelete: "Tem certeza de que deseja excluir esta seção?",
        moveUp: "Mover para cima",
        moveDown: "Mover para baixo",
        sectionSaved: "Seção salva com sucesso",
        sectionDeleted: "Seção excluída com sucesso",
        changesSaved: "Todas as alterações foram salvas com sucesso",
        markdownSupported: "Markdown é suportado para formatação.",
        loading: "Carregando...",
        error: "Erro ao carregar os Termos e Condições. Por favor, tente novamente mais tarde.",
    }
};

const TermsContent = ({ content }) => {
    const createMarkup = (html) => {
        const sanitizedHtml = DOMPurify.sanitize(html);
        const formattedHtml = sanitizedHtml.replace(
            /<div class="policy-detail[^>]*>([\s\S]*?)<\/div>/g,
            (match, p1) => {
                const trimmedContent = p1.trim().split('\n').map((line, index, array) => {
                    if (index === 0 || index === array.length - 1) {
                        return line.trim();
                    }
                    return line;
                }).join('\n');

                const escapedContent = trimmedContent
                    .replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&#039;')
                    .replace(/\n/g, '<br>')
                    .replace(/\s{2,}/g, match => '&nbsp;'.repeat(match.length));
                return `<div class="policy-detail text-gray-600 dark:text-gray-400 whitespace-pre-wrap">${escapedContent}</div>`;
            }
        );
        return { __html: formattedHtml };
    };

    return (
        <div
            className="terms-content prose dark:prose-invert max-w-none"
            dangerouslySetInnerHTML={createMarkup(content)}
        />
    );
};

export const EditTermsAndConditions = () => {
    const [sections, setSections] = useState([]);
    const [editingSectionIndex, setEditingSectionIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [template, setTemplate] = useState('');
    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        const fetchTermsAndConditions = async () => {
            try {
                setIsLoading(true);
                const existingTerms = await getTermsAndConditions();
                if (existingTerms && existingTerms.length > 0) {
                    setTemplate(existingTerms);
                    const parsedSections = parseTermsAndConditions(existingTerms);
                    setSections(parsedSections);
                    saveToLocalStorage('termsAndConditions', parsedSections);
                } else {
                    const storedSections = getFromLocalStorage('termsAndConditions', []);
                    setSections(storedSections);
                }
            } catch (error) {
                console.error("Error fetching terms and conditions:", error);
                setError(t.error);
                const storedSections = getFromLocalStorage('termsAndConditions', []);
                setSections(storedSections);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTermsAndConditions();
    }, [t.error]);

    const parseTermsAndConditions = (termsHtml) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(termsHtml, 'text/html');
        const sections = Array.from(doc.querySelectorAll('section')).map(section => ({
            title: section.querySelector('h3').textContent,
            content: section.querySelector('.policy-detail').innerHTML
        }));
        return sections;
    };

    const handleAddSection = () => {
        if (sections.length > 0) {
            const lastSection = sections[sections.length - 1];
            if (!lastSection.title.trim() || !lastSection.content.trim()) {
                toastr.warning(t.fillPreviousSection);
                return;
            }
        }

        const newSections = [...sections, { title: '', content: '' }];
        setSections(newSections);
        setEditingSectionIndex(newSections.length - 1);
        saveToLocalStorage('termsAndConditions', newSections);
    };

    const handleEditSection = (index) => {
        setEditingSectionIndex(index);
    };

    const handleSaveChanges = async (index, newTitle, newContent) => {
        const newSections = [...sections];
        newSections[index] = {
            title: newTitle,
            content: newContent
        };
        setSections(newSections);
        saveToLocalStorage('termsAndConditions', newSections);
        setEditingSectionIndex(null);

        const updatedTemplate = generateReactTemplate(newSections);
        await sendUpdatedTemplate(updatedTemplate);

        toastr.success(t.sectionSaved);
    };

    const generateReactTemplate = (sections) => {
        return `
            <div class="terms-container max-w-3xl mx-auto p-4">
                <header class="mb-4">
                    <h1 class="text-3xl font-bold text-gray-900 dark:text-white">ABADA - Capoeira Australia</h1>
                    <h2 class="text-2xl font-bold text-gray-900 dark:text-white">${t.termsAndConditions}</h2>
                </header>
                ${sections.map((section, index) => `
                <section class="mb-6">
                    <h3 class="text-xl font-bold text-gray-900 dark:text-white">${section.title}</h3>
                    <div class="policy-detail text-gray-600 dark:text-gray-400">
                        ${section.content}
                    </div>
                </section>
                `).join('')}
            </div>
        `;
    };

    const handleCancel = () => {
        setEditingSectionIndex(null);
    };

    const handleDeleteSection = async (index) => {
        if (window.confirm(t.confirmDelete)) {
            const newSections = [...sections];
            newSections.splice(index, 1);
            setSections(newSections);
            saveToLocalStorage('termsAndConditions', newSections);
            setEditingSectionIndex(null);

            const updatedTemplate = generateReactTemplate(newSections);
            await sendUpdatedTemplate(updatedTemplate);

            toastr.success(t.sectionDeleted);
        }
    };

    const handleMoveSection = (index, direction) => {
        const newSections = [...sections];
        const newIndex = index + direction;
        if (newIndex >= 0 && newIndex < newSections.length) {
            [newSections[index], newSections[newIndex]] = [newSections[newIndex], newSections[index]];
            setSections(newSections);
            saveToLocalStorage('termsAndConditions', newSections);
        }
    };

    const sendUpdatedTemplate = async (template) => {
        setIsLoading(true);
        try {
            await updateTermsAndConditions(template)
            toastr.success('Full template updated successfully.');
        } catch (error) {
            console.error('Error')
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <ULoading />;
    }

    if (error) {
        return (
            <div className="container mx-auto p-4 max-w-3xl">
                <UCard
                    title={t.termsAndConditions}
                    bodyClass="text-center text-red-500 dark:text-red-400"
                >
                    {error}
                </UCard>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <UCard
                title={t.termsAndConditions}
                bodyClass="space-y-4"
                footerClass="flex justify-between items-center"
                footer={
                    <>
                        <UButton className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark transition-colors" onClick={handleAddSection}>
                            {t.addSection}
                        </UButton>
                        <UButton className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition-colors" onClick={async () => {
                            const updatedTemplate = generateReactTemplate(sections);
                            await sendUpdatedTemplate(updatedTemplate);
                        }}>
                            {t.saveChanges}
                        </UButton>
                    </>
                }
            >
                {editingSectionIndex === null ? (
                    <TermsContent content={template} />
                ) : (
                    sections.map((section, index) => (
                        <div
                            key={index}
                            className="mb-6"
                            draggable
                        >
                            {editingSectionIndex === index ? (
                                <UCard>
                                    <UInput
                                        type="text"
                                        value={section.title}
                                        onChange={(e) => {
                                            const newSections = [...sections];
                                            newSections[index].title = e.target.value;
                                            setSections(newSections);
                                        }}
                                        placeholder={t.sectionTitle}
                                        className="w-full p-2 border border-gray-300 rounded mb-2"
                                    />
                                    <textarea
                                        className={`w-full px-3 py-2 placeholder-gray-400 text-gray-900 dark:text-white bg-white dark:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent resize-y overflow-y-auto min-h-20 max-h-50`}
                                        value={section.content}
                                        onChange={(e) => {
                                            const newSections = [...sections];
                                            newSections[index].content = e.target.value;
                                            setSections(newSections);
                                        }}
                                        placeholder={t.sectionContent}
                                    />
                                    <p className="text-sm text-gray-500 mt-1">{t.markdownSupported}</p>
                                    <div className="flex gap-4 mt-2">
                                        <UButton className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark transition-colors" onClick={() => handleSaveChanges(index, section.title, section.content)}>
                                            {t.saveChanges}
                                        </UButton>
                                        <UButton className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-800 transition-colors" onClick={handleCancel}>
                                            {t.cancel}
                                        </UButton>
                                        <UButton className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition-colors" onClick={() => handleDeleteSection(index)}>
                                            {t.deleteSection}
                                        </UButton>
                                    </div>
                                </UCard>
                            ) : (
                                <UCard>
                                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">{section.title}</h2>
                                    <div className="text-gray-600 dark:text-gray-400 mb-4">
                                        <ReactMarkdown>{section.content}</ReactMarkdown>
                                    </div>
                                    <div className="flex gap-4">
                                        <UButton className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark transition-colors" onClick={() => handleEditSection(index)}>
                                            {t.editSection}
                                        </UButton>
                                        <UButton className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition-colors" onClick={() => handleDeleteSection(index)}>
                                            {t.deleteSection}
                                        </UButton>
                                        <UButton className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700 transition-colors" onClick={() => handleMoveSection(index, -1)} disabled={index === 0}>
                                            {t.moveUp}
                                        </UButton>
                                        <UButton className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700 transition-colors" onClick={() => handleMoveSection(index, 1)} disabled={index === sections.length - 1}>
                                            {t.moveDown}
                                        </UButton>
                                    </div>
                                </UCard>
                            )}
                        </div>
                    ))
                )}
            </UCard>
        </div>
    );
};
