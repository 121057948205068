/*
 * Tomas Costantino
 *
 * Handle sign up with server
 */

import axios from "axios";


export async function signUpUser(data) {
    return axios.post(`${process.env.REACT_APP_API_BASE}/user`, data)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            localStorage.clear();
            throw new Error(error);
        });
}

export async function postUserPhoto(data) {
    return axios.post(`${process.env.REACT_APP_API_BASE}/user/photo`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error);
        })
}
