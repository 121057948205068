/**
 * Tomas Costantino
 *
 * Reset terms and conditions server interaction
 */

import axios from "axios";

export function updateTermsAndConditions (data) {
    return axios.post(`${process.env.REACT_APP_API_BASE}/terms-and-conditions`, { template: data })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error);
        })
}

export function getTermsAndConditions () {
    return axios.get(`${process.env.REACT_APP_API_BASE}/terms-and-conditions`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error);
        })
}
