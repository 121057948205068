import React, { useState, useEffect } from 'react';
import { UCard } from "../CustomComponents/UCard";
import { ULoading } from "../CustomComponents/ULoading";
import { USelect } from "../CustomComponents/USelect";
import { UInput } from "../CustomComponents/UInput";
import { UButton } from "../CustomComponents/UButton";
import { UModal } from "../CustomComponents/UModal";
import { getTournaments } from "../server/tournament/[...].tournament.get";
import { updateTournament } from "../server/tournament/[...].tournament.patch";
import { deleteTournament } from "../server/tournament/[...].tournament.delete";
import { getFromLocalStorage, saveToLocalStorage } from "../utils/localStorageHandler";
import { useLanguage } from "../contexts/LanguageContext";
import toastr from 'toastr';

const translations = {
    en: {
        editTournamentDetails: "Edit Tournament Details",
        selectTournament: "Select a Tournament",
        editDetails: "Edit Details",
        name: "Name",
        date: "Date",
        location: "Location",
        saveChanges: "Save Changes",
        cancel: "Cancel",
        deleteTournament: "Delete Tournament",
        noTournaments: "No tournaments available.",
        confirmDelete: "Confirm Delete",
        deleteConfirmMessage: "This action is irreversible. Are you sure you want to delete this tournament and all related data?",
        yesDelete: "Yes, Delete",
        areYouReallySure: "Are you really sure?",
        errorFetchingData: "Error: Unable to fetch data from the server.",
        tournamentUpdated: "Tournament details updated successfully",
        errorUpdating: "Error updating tournament details",
        tournamentDeleted: "Tournament deleted successfully",
        errorDeleting: "Error deleting Tournament"
    },
    pt: {
        editTournamentDetails: "Editar Detalhes do Torneio",
        selectTournament: "Selecione um Torneio",
        editDetails: "Editar Detalhes",
        name: "Nome",
        date: "Data",
        location: "Local",
        saveChanges: "Salvar Alterações",
        cancel: "Cancelar",
        deleteTournament: "Excluir Torneio",
        noTournaments: "Nenhum torneio disponível.",
        confirmDelete: "Confirmar Exclusão",
        deleteConfirmMessage: "Esta ação é irreversível. Tem certeza de que deseja excluir este torneio e todos os dados relacionados?",
        yesDelete: "Sim, Excluir",
        areYouReallySure: "Você tem certeza absoluta?",
        errorFetchingData: "Erro: Não foi possível buscar dados do servidor.",
        tournamentUpdated: "Detalhes do torneio atualizados com sucesso",
        errorUpdating: "Erro ao atualizar detalhes do torneio",
        tournamentDeleted: "Torneio excluído com sucesso",
        errorDeleting: "Erro ao excluir o Torneio"
    }
};

export const TournamentSettings = () => {
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [originalData, setOriginalData] = useState({});
    const [loading, setLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        fetchTournaments();
    }, []);

    const fetchTournaments = async () => {
        setLoading(true);
        try {
            const fetchedTournaments = await getTournaments();
            setTournaments(fetchedTournaments);
            saveToLocalStorage('tournamentsToEdit', fetchedTournaments);
            if (fetchedTournaments.length > 0) {
                const cachedSelectedTournament = getFromLocalStorage('selectedTournamentToEdit', null);
                if (cachedSelectedTournament && fetchedTournaments.find(t => t.tournament_id === cachedSelectedTournament.tournament_id)) {
                    setSelectedTournament(cachedSelectedTournament);
                } else {
                    setSelectedTournament(fetchedTournaments[0]);
                    saveToLocalStorage('selectedTournamentToEdit', fetchedTournaments[0]);
                }
            } else {
                setSelectedTournament(null);
                saveToLocalStorage('selectedTournamentToEdit', null);
            }
        } catch {
            setServerError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isEditing) {
            setOriginalData(selectedTournament);
        } else {
            setOriginalData({});
        }
    }, [isEditing, selectedTournament]);

    const handleTournamentChange = (value) => {
        const tournament = tournaments.find(c => c.tournament === value);
        setSelectedTournament(tournament);
        saveToLocalStorage('selectedTournamentToEdit', tournament);
        setIsEditing(false);
    };

    const handleInputChange = (name, value) => {
        setSelectedTournament((prevTournament) => ({ ...prevTournament, [name]: value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const updatedTournament = { ...selectedTournament };
            delete updatedTournament.tournament_id;
            const response = await updateTournament(selectedTournament['tournament_id'], updatedTournament);
            if (response.success) {
                toastr.success(t.tournamentUpdated);
                await fetchTournaments();
            } else {
                toastr.error(response.error);
                setSelectedTournament(originalData);
            }
        } catch (error) {
            toastr.error(t.errorUpdating);
            setSelectedTournament(originalData);
        } finally {
            setIsEditing(false);
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setSelectedTournament(originalData);
        setIsEditing(false);
    };

    const handleDeleteClick = () => {
        if (!confirmDelete) {
            setConfirmDelete(true);
        } else {
            handleDeleteTournament();
        }
    };

    const closeModal = () => {
        setShowDeleteModal(false);
        setConfirmDelete(false);
    };

    const handleDeleteTournament = async () => {
        setLoading(true);
        try {
            const response = await deleteTournament(selectedTournament['tournament_id']);
            if (response) {
                toastr.success(t.tournamentDeleted);
                await fetchTournaments();
                closeModal();
            }
        } catch {
            toastr.error(t.errorDeleting);
        } finally {
            setLoading(false);
        }
    }

    if (serverError) {
        return (
            <UCard>
                <div className="text-red-500 text-center">{t.errorFetchingData}</div>
            </UCard>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <UCard
                title={t.editTournamentDetails}
                bodyClass="space-y-6"
            >
                {loading ? (
                    <ULoading />
                ) : (
                <div className="mb-4">
                    <div className="text-lg font-medium mb-2">{t.selectTournament}</div>
                    <USelect
                        options={tournaments.map(t => ({ value: t.tournament, label: t.tournament }))}
                        value={selectedTournament?.tournament || ''}
                        onChange={handleTournamentChange}
                        placeholder={t.selectTournament}
                        disabled={tournaments.length === 0 || loading}
                        className="w-full"
                    />
                    {!isEditing && selectedTournament && (
                        <UButton onClick={() => setIsEditing(true)}
                                 disabled={tournaments.length === 0}
                                 className="mt-4 w-full sm:w-auto bg-primary hover:bg-primary-dark">
                            {t.editDetails}
                        </UButton>
                    )}
                </div>
                )}

                {selectedTournament ? (
                    <div className="space-y-4">
                        <UInput
                            label={t.name}
                            name="tournament"
                            value={selectedTournament.tournament || ''}
                            onChange={(e) => handleInputChange('tournament', e.target.value)}
                            disabled={!isEditing || loading}
                            className="w-full"
                        />
                        <UInput
                            type="date"
                            label={t.date}
                            name="date"
                            value={selectedTournament.date || ''}
                            onChange={(e) => handleInputChange('date', e.target.value)}
                            disabled={!isEditing || loading}
                            className="w-full"
                        />
                        <UInput
                            label={t.location}
                            name="location"
                            value={selectedTournament.location || ''}
                            onChange={(e) => handleInputChange('location', e.target.value)}
                            disabled={!isEditing || loading}
                            className="w-full"
                        />
                        {isEditing && (
                            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                                <UButton onClick={handleSubmit} disabled={loading} className="w-full sm:w-auto bg-green-500 hover:bg-green-600">
                                    {t.saveChanges}
                                </UButton>
                                <UButton onClick={handleCancel} disabled={loading} className="w-full sm:w-auto bg-primary hover:bg-primary-dark">
                                    {t.cancel}
                                </UButton>
                                <UButton onClick={() => setShowDeleteModal(true)} variant="danger" disabled={loading} className="w-full sm:w-auto">
                                    {t.deleteTournament}
                                </UButton>
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        {!loading && (
                            <p className="text-center text-gray-500">{t.noTournaments}</p>
                        )}
                    </>
                )}
            </UCard>

            <UModal isOpen={showDeleteModal} onClose={closeModal}>
                <h2 className="text-xl font-bold mb-4">{t.confirmDelete}</h2>
                <p className="mb-4">{t.deleteConfirmMessage}</p>
                <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2">
                    <UButton onClick={handleDeleteClick} variant="danger" className="w-full sm:w-auto">
                        {confirmDelete ? t.areYouReallySure : t.yesDelete}
                    </UButton>
                    <UButton onClick={closeModal} variant="secondary" className="w-full sm:w-auto">
                        {t.cancel}
                    </UButton>
                </div>
            </UModal>
        </div>
    );
};
