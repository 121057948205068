import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import toastr from 'toastr';
import { saveToLocalStorage } from "../utils/localStorageHandler";
import { resetPassword } from "../server/password/[...].reset";
import { postLogin } from "../server/login/[...].login.post";
import { UCard } from '../CustomComponents/UCard';
import { UInput } from "../CustomComponents/UInput";
import { ThemeLanguageToggle } from '../CustomComponents/ThemeLanguageToggle';
import { useLanguage } from "../contexts/LanguageContext";
import {ULoading} from "../CustomComponents/ULoading";

const translations = {
    en: {
        resetPasswordTitle: "Reset Password",
        nickname: "Nickname",
        newPassword: "New Password",
        repeatNewPassword: "Repeat New Password",
        submit: "Reset Password",
        cancel: "Cancel",
        incompleteFields: "Incomplete fields",
        shortPassword: "Password must be at least 8 characters",
        noMatchPassword: "Passwords do not match",
        success: "Password updated successfully, logging in.",
        error: "Server error",
        noPhoneNumber: "Incorrect phone number",
        wrongNickname: "Nickname not in database"
    },

    pt: {
        resetPasswordTitle: "Redefinir Senha",
        nickname: "Apelido",
        newPassword: "Nova Senha",
        repeatNewPassword: "Repita a Nova Senha",
        submit: "Redefinir Senha",
        cancel: "Cancelar",
        incompleteFields: "Campos incompletos",
        shortPassword: "A senha deve conter pelo menos 8 caracteres",
        noMatchPassword: "As senhas não coincidem",
        success: "Senha atualizada com sucesso, efetuando login.",
        error: "Erro de servidor",
        noPhoneNumber: "Número de telefone incorreto",
        wrongNickname: "Apelido não está no banco de dados"
    }
};

export const ResetPassword = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        nickname: '',
        phoneNumber: '',
        newPassword: '',
        repeatNewPassword: ''
    });
    const [loading, setLoading] = useState(false);

    const { language } = useLanguage();
    const t = translations[language];

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, phoneNumber: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (Object.values(formData).some(value => value.length === 0)) {
            toastr.error(t.incompleteFields);
            return;
        }

        if (formData.newPassword.length < 8) {
            toastr.error(t.shortPassword);
            return;
        }

        if (formData.newPassword !== formData.repeatNewPassword) {
            toastr.error(t.noMatchPassword);
            return;
        }

        setLoading(true);

        try {
            const response = await resetPassword({...formData, nickname: formData.nickname.trim()});
            if (response.success) {
                toastr.success(t.success);
                const loginResponse = await postLogin({ nickname: formData.nickname.trim(), password: formData.newPassword });
                if (loginResponse.status === 200) {
                    saveToLocalStorage('userId', loginResponse.data.user_id);
                    saveToLocalStorage('userPermissions', loginResponse.data.user_type);
                    saveToLocalStorage('authToken', true);
                    navigate('/profile');
                }
            } else if (response.error.includes('number')) {
                toastr.error(t.noPhoneNumber);
            } else {
                toastr.error(t.wrongNickname);
            }
        } catch {
            toastr.error(t.error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = (event) => {
        event.preventDefault();
        navigate('/login');
    }

    return (
        <div className="min-h-screen flex flex-col items-center justify-center">
            <div className="absolute top-5 right-5 space-x-2 flex items-center">
                <ThemeLanguageToggle/>
            </div>
            <UCard
                title={<h2
                    className="text-2xl font-bold text-text-DEFAULT dark:text-text-dark">{t.resetPasswordTitle}</h2>}
                headerClass="flex justify-between items-center"
                bodyClass="space-y-4"
            >
                <form onSubmit={handleSubmit} className="space-y-4">
                    <UInput
                        type="text"
                        name="nickname"
                        value={formData.nickname}
                        onChange={handleChange}
                        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                        placeholder={t.nickname}
                    />
                    <PhoneInput
                        country={'au'}
                        onChange={handlePhoneChange}
                        containerClass="w-full"
                        inputClass="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                    />
                    <UInput
                        type="password"
                        name="newPassword"
                        value={formData.newPassword}
                        onChange={handleChange}
                        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                        placeholder={t.newPassword}
                    />
                    <UInput
                        type="password"
                        name="repeatNewPassword"
                        value={formData.repeatNewPassword}
                        onChange={handleChange}
                        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                        placeholder={t.repeatNewPassword}
                    />
                    {loading && (
                        <ULoading />
                    )}
                    <div className="flex justify-between">
                        <button type="submit" disabled={loading}
                                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
                            {t.submit}
                        </button>
                        <button type="button" onClick={handleCancel} disabled={loading}
                                className="px-4 py-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400">
                            {t.cancel}
                        </button>
                    </div>
                </form>
            </UCard>
        </div>
    );
}
