import React, { useState, useEffect } from 'react';
import { UCard } from '../CustomComponents/UCard';
import { UTable } from '../CustomComponents/UTable';
import { ThemeLanguageToggle } from '../CustomComponents/ThemeLanguageToggle';
import { getUserPermissions } from "../server/user/[...].user.get";
import { patchUserPermissions } from "../server/user/[...].user.patch";
import { getFromLocalStorage, saveToLocalStorage } from "../utils/localStorageHandler";
import { useLanguage } from "../contexts/LanguageContext";
import toastr from 'toastr';
import { ULoading } from "../CustomComponents/ULoading";
import {UButton} from "../CustomComponents/UButton";
import {UInput} from "../CustomComponents/UInput";
import {USelect} from "../CustomComponents/USelect";

const translations = {
    en: {
        title: "Set Permissions",
        pending: "Pending Changes",
        save: "Save Changes",
        competitor: "Competitor",
        judge: "Judge",
        admin: "Admin",
        search: "Search profiles...",
        delete: "Delete",
        serverError: "Error: Unable to fetch data from the server.",
        noChanges: "No changes to submit.",
        changesMade: "Changes made!",
        failedSubmit: "Failed to submit changes",
    },
    pt: {
        title: "Definir Permissões",
        pending: "Alterações Pendentes",
        save: "Salvar Alterações",
        competitor: "Jogador",
        judge: "Juiz",
        admin: "Admin",
        search: "Perfis de pesquisa...",
        delete: "Excluir",
        serverError: "Erro: Não é possível buscar dados do servidor.",
        noChanges: "Nenhuma alteração para enviar.",
        changesMade: "Mudanças feitas!",
        failedSubmit: "Falha ao enviar alterações",
    }
};

export const SetPermissions = () => {
    const [people, setPeople] = useState(getFromLocalStorage('usersPermissions', []));
    const [searchTerm, setSearchTerm] = useState('');
    const [changes, setChanges] = useState(getFromLocalStorage('permissionChanges', []));
    const [serverError, setServerError] = useState(false);
    const [loading, setLoading] = useState(false);

    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        saveToLocalStorage('usersPermissions', people);
        saveToLocalStorage('permissionChanges', changes);
    }, [people, changes]);

    useEffect(() => {
        const fetchUserPermissions = async () => {
            let permissionChanges = getFromLocalStorage('permissionChanges', []);
            if (permissionChanges && permissionChanges.length > 0) {
                return;
            }
            setLoading(true);
            try {
                const response = await getUserPermissions();
                if (response) {
                    setPeople(response);
                }
            } catch (error) {
                setServerError(true);
            } finally {
                setLoading(false);
            }
        }
        fetchUserPermissions();
    }, []);

    const handleRoleChange = (user_id, newRole) => {
        const person = people.find(p => p.user_id === user_id);
        if (!person) return;

        const personId = person.user_id;
        const oldRole = person.user_type;
        const updatedPeople = people.map(p =>
            p.user_id === personId ? { ...p, user_type: newRole } : p
        );
        setPeople(updatedPeople);

        const existingChangeIndex = changes.findIndex(change => change.id === personId);
        if (existingChangeIndex >= 0) {
            const updatedChanges = changes.map((change, index) =>
                index === existingChangeIndex ? { ...change, newRole } : change
            );
            setChanges(updatedChanges);
        } else {
            setChanges(prevChanges => [...prevChanges, { id: personId, oldRole, newRole }]);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleDeleteChange = (changeIndex) => {
        const deletedChange = changes[changeIndex];

        const updatedChanges = changes.filter((_, index) => index !== changeIndex);
        setChanges(updatedChanges);

        const updatedPeople = people.map(person =>
            person.user_id === deletedChange.id ? { ...person, user_type: deletedChange.oldRole } : person
        );
        setPeople(updatedPeople);
        saveToLocalStorage('usersPermissions', updatedPeople);
    };

    const handleSubmitChanges = async () => {
        if (!changes || changes.length === 0) {
            toastr.warning(t.noChanges);
            return;
        }
        setLoading(true);
        try {
            const formattedChanges = changes.map(({ id, newRole }) => ({ user_id: id, newRole }));
            const response = await patchUserPermissions(formattedChanges);
            if (response) {
                setChanges([]);
                toastr.success(t.changesMade);
            }
        } catch (error) {
            toastr.error(t.failedSubmit);
        } finally {
            setLoading(false);
        }
    };

    const filteredPeople = people.filter(person =>
        person.nickname.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const getPersonNickname = (userId) => {
        return people.find(user => user.user_id === userId)?.nickname;
    }

    const roleOptions = [
        { value: 'admin', label: t.admin },
        { value: 'competitor', label: t.competitor },
        { value: 'judge', label: t.judge },
    ];

    const tableData = filteredPeople.map(person => ({
        nickname: person.nickname,
        role: (
            <USelect
                options={roleOptions}
                value={person.user_type}
                onChange={(value) => handleRoleChange(person.user_id, value)}
                placeholder={t.selectRole}
            />
        )
    }));


    if (serverError) {
        return (
            <div className="container mx-auto px-4 py-8">
                <UCard>
                    <div className="text-red-500 text-center">{t.serverError}</div>
                </UCard>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <UCard
                title={<h2 className="text-2xl font-bold text-text-DEFAULT dark:text-text-dark">{t.title}</h2>}
                headerClass="flex justify-between items-center"
                headerExtra={<ThemeLanguageToggle />}
                bodyClass="space-y-6"
            >
                {loading && (
                    <ULoading />
                )}

                <div className="space-y-4">
                    <h3 className="text-xl justify-center text-center font-semibold text-text-DEFAULT dark:text-text-dark">{t.pending}</h3>
                    {changes.map((change, index) => (
                        <div key={index} className="flex justify-between items-center bg-background-light dark:bg-darkMode-lighter p-2 rounded">
                            <span className="text-text-DEFAULT dark:text-text-dark">
                                {getPersonNickname(change.id)}: {t[change.oldRole]} → {t[change.newRole]}
                            </span>
                            <button
                                onClick={() => handleDeleteChange(index)}
                                className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                            >
                                {t.delete}
                            </button>
                        </div>
                    ))}
                    <UButton
                        onClick={handleSubmitChanges} disabled={loading}
                        className="w-full px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark transition-colors"
                    >
                        {t.save}
                    </UButton>
                </div>

                <UInput
                    type="text"
                    className="w-full p-2 border border-secondary-light dark:border-darkMode-lighter rounded-md bg-background-light dark:bg-darkMode-DEFAULT text-text-DEFAULT dark:text-text-dark"
                    placeholder={t.search}
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                <UTable
                    headers={[]}
                    data={tableData}
                />
            </UCard>
        </div>
    );
};
