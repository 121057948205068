import React from 'react';

export const UTable = ({ headers, data, onRowClick }) => {
    return (
        <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-secondary-light dark:divide-darkMode-lighter">
                <thead className="bg-background-light dark:bg-darkMode-lighter">
                <tr>
                    {headers.map((header, index) => (
                        <th
                            key={index}
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-text-light dark:text-text-dark uppercase tracking-wider"
                        >
                            {header}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody className="bg-background-DEFAULT dark:bg-darkMode-DEFAULT divide-y divide-secondary-light dark:divide-darkMode-lighter">
                {data.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        onClick={onRowClick ? () => onRowClick(row) : undefined}
                        className={`${onRowClick ? 'cursor-pointer hover:bg-secondary-light dark:hover:bg-darkMode-lighter' : ''} transition-colors duration-150 ease-in-out`}
                    >
                        {Object.values(row).map((cell, cellIndex) => (
                            <td
                                key={cellIndex}
                                className="px-6 py-4 whitespace-nowrap text-sm text-text-DEFAULT dark:text-text-dark"
                            >
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};