import React from 'react';

export const UCard = ({
                          title,
                          subtitle,
                          children,
                          headerClass = '',
                          bodyClass = '',
                          footerClass = '',
                          footer
                      }) => {
    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md border border-gray-200 dark:border-gray-700">
            {(title || subtitle) && (
                <div className={`px-6 py-4 border-b border-gray-200 dark:border-gray-700 ${headerClass} flex flex-col justify-center`}>
                    {title && <h1 className="text-2xl font-semibold text-gray-900 dark:text-white text-center">{title}</h1>}
                    {subtitle && <p className="mt-1 text-sm text-gray-500 dark:text-gray-300 text-center">{subtitle}</p>}
                </div>
            )}
            <div className={`px-6 py-4 ${bodyClass}`}>
                {children}
            </div>
            {footer && (
                <div className={`px-6 py-4 rounded-b-lg bg-gray-50 dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 ${footerClass}`}>
                    {footer}
                </div>
            )}
        </div>
    );
};
