import React, { useState, useEffect } from 'react';
import { UCard } from "../CustomComponents/UCard";
import { ULoading } from "../CustomComponents/ULoading";
import { UInput } from "../CustomComponents/UInput";
import { ThemeLanguageToggle } from "../CustomComponents/ThemeLanguageToggle";
import { getKeyData } from "../server/key_data/[...].key_data.get";
import { getFromLocalStorage, saveToLocalStorage } from "../utils/localStorageHandler";
import { useLanguage } from "../contexts/LanguageContext";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import { postTournament } from "../server/tournament/[...].tournament.post";

const translations = {
    en: {
        createTournament: "Create tournament",
        tournamentName: "Tournament Name",
        tournamentDate: "Tournament Date",
        tournamentLocation: "Tournament Location",
        insertCategories: "Please insert categories",
        tournamentCreated: "Tournament successfully created!",
        stylesLength: "All categories must have at least 2 styles",
        categoriesSelect: "Select Categories",
        selectBelts: "Please select belts",
        selectGenders: "Select genders",
        addCategoryButton: 'Add category',
        categoryGroupN: 'Category Group',
        categoryName: 'Category Name',
        categoryNameAutomaticGeneration: 'Leave blank for automatic generation',


        fillIn: "Please fill in",
        selectCategories: "Please add categories",
        tournamentExists: "Tournament already exists",
        beltsSelect: "Select Belt to Merge",
        addGroup: "Add categories",
        delete: "Delete",
        tournamentDetails: "Tournament Details",
        categories: "Categories",
        festivalMode: "Festival Mode",
        autoAdd: "Add everyone automatically",
        insertGender: "Please select at least one gender",
        selectStyles: "Select styles"

    },
    pt: {
        createTournament: "Criar Competição",
        tournamentName: "Nome do Competição",
        tournamentDate: "Data do Competiçao",
        tournamentLocation: "Local do Competição",
        insertCategories: "Por favor insira categorias",
        tournamentCreated: "Torneio criado com sucesso!",
        stylesLength: "Todas as categorias devem ter pelo menos 2 estilos",
        categoriesSelect: "Selecione a categoria",
        beltsSelect: "Selecione o cinto para mesclar",
        selectGenders: "Selecione os gêneros",
        addCategoryButton: 'Adicionar categoria',
        categoryGroupN: 'Categoria Grupo',


        fillIn: {
            name: "Por favor preencha o nome",
            date: "Por favor preencha a data",
            location: "Por favor preencha o local"
        },

        selectCategories: "Adicione categorias",
        selectBelts: "Por favor selecione cintos",
        tournamentExists: "O torneio já existe",

        addGroup: "Adicionar categorias",
        delete: "Excluir",
        tournamentDetails: "Detalhes do Competição",
        categories: "Categorias",
        festivalMode: "Modo Festival",
        autoAdd: "Adicionar todos automaticamente",
        insertGender: "Por favor, selecione pelo menos um gênero",

        categoryName: 'Nome de Categoria',
        categoryNameAutomaticGeneration: 'Deixe em branco para geração automática',
        selectStyles: "Selecione estilos"

    }
};
export const CreateTournament = () => {
    const [tournamentData, setTournamentData] = useState({
        name: '',
        date: '',
        location: '',
        categories: []
    });
    const [serverData, setServerData] = useState(getFromLocalStorage('keyData', {}));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [openBeltDropdowns, setOpenBeltDropdowns] = useState([]);
    const [openCategoryDropdowns, setOpenCategoryDropdowns] = useState([]);
    const { language } = useLanguage();
    const navigate = useNavigate();

    const t = translations[language];

    useEffect(() => {
        if (Object.keys(serverData).length === 0) {
            fetchServerData();
        }
    }, [serverData]);

    const fetchServerData = async () => {
        setLoading(true);
        try {
            const response = await getKeyData();
            setServerData(response);
            saveToLocalStorage('keyData', response);
        } catch (error) {
            setError('Failed to fetch server data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTournamentData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        if (tournamentData.categories.length === 0) {
            toastr.error('Need at least 1 category group');
            return;
        }

        // eslint-disable-next-line array-callback-return
        tournamentData.categories.map((category, index) => {
            // Check if at least one category is selected
            if (!category.categories || category.categories.length === 0) {
                toastr.error(`Group ${index + 1}: Please select at least one category.`);
                isValid = false;
            }

            // Check if belt is selected when needed
            const needsBelt = category.categories?.some(catId =>
                serverData.categories_with_belt.some(beltCat => beltCat.category_id.toString() === catId)
            );
            if (needsBelt && (!category.belts || category.belts.length === 0)) {
                toastr.error(`Group ${index + 1}: Please select at least one belt for the chosen categories.`);
                isValid = false;
            }

            // Check if at least two styles are selected
            if (!category.styles || category.styles.length < 2) {
                toastr.error(`Group ${index + 1}: Please select at least two styles.`);
                isValid = false;
            }

            // Check if at least one gender is selected
            if (!category.genders || category.genders.length === 0) {
                toastr.error(`Category group ${index + 1}: Please select at least one gender.`);
                isValid = false;
            }
        });

        if (!isValid) {
            return;
        }

        try {
            setLoading(true);
            await postTournament(tournamentData);
            toastr.success('Tournament successfully created');
            setTimeout(() => {navigate('/admin/tournament-draws')}, 1000);
        } catch (error) {
            console.error("Failed to create tournament status:", error);
            toastr.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCategoryChange = (index, category) => {
        const newCategories = [...tournamentData.categories];
        const currentCategories = newCategories[index].categories || [];
        const categoryIndex = currentCategories.indexOf(category.value);

        if (categoryIndex > -1) {
            // Remove the category
            currentCategories.splice(categoryIndex, 1);
        } else {
            // Add the category
            currentCategories.push(category.value);
        }

        newCategories[index].categories = currentCategories;

        // Check if any of the selected categories require belts
        const needsBelts = currentCategories.some(catId =>
            serverData.categories_with_belt.some(beltCat => beltCat.category_id.toString() === catId)
        );

        // If no categories require belts, clear the belt selection
        if (!needsBelts) {
            newCategories[index].belts = [];
        }

        setTournamentData(prev => ({ ...prev, categories: newCategories }));
    };

    const handleBeltChange = (index, belt) => {
        const newCategories = [...tournamentData.categories];
        const currentBelts = newCategories[index].belts || [];
        const beltIndex = currentBelts.indexOf(belt.value);

        if (beltIndex > -1) {
            currentBelts.splice(beltIndex, 1);
        } else {
            currentBelts.push(belt.value);
        }

        newCategories[index].belts = currentBelts;
        setTournamentData(prev => ({ ...prev, categories: newCategories }));
    };

    const handleGenderChange = (index, gender) => {
        const newCategories = [...tournamentData.categories];
        const currentGenders = newCategories[index].genders || [];
        const genderIndex = currentGenders.indexOf(gender.value);

        if (genderIndex > -1) {
            currentGenders.splice(genderIndex, 1);
        } else {
            currentGenders.push(gender.value);
        }

        newCategories[index].genders = currentGenders;
        setTournamentData(prev => ({ ...prev, categories: newCategories }));
    };

    const handleCategoryNameChange = (index, value) => {
        if (!tournamentData.categories[index].hasOwnProperty('category_name')) {
            tournamentData.categories[index].category_name = '';
        }
        tournamentData.categories[index].category_name = value;
    };

    const handleStyleChange = (index, style) => {
        const newCategories = [...tournamentData.categories];
        const currentStyles = newCategories[index].styles || [];
        const styleIndex = currentStyles.indexOf(style.value);

        if (styleIndex > -1) {
            currentStyles.splice(styleIndex, 1);
        } else {
            currentStyles.push(style.value);
        }

        newCategories[index].styles = currentStyles;
        setTournamentData(prev => ({ ...prev, categories: newCategories }));
    };

    const handleDeleteRow = (index) => {
        setTournamentData(prev => ({
            ...prev,
            categories: prev.categories.filter((_, i) => i !== index)
        }));
    };

    const toggleCategoryDropdown = (index) => {
        setOpenCategoryDropdowns(prev => {
            const newOpenDropdowns = [...prev];
            newOpenDropdowns[index] = !newOpenDropdowns[index];
            return newOpenDropdowns;
        });
    };

    const toggleBeltDropdown = (index) => {
        setOpenBeltDropdowns(prev => {
            const newOpenDropdowns = [...prev];
            newOpenDropdowns[index] = !newOpenDropdowns[index];
            return newOpenDropdowns;
        });
    };

    const renderCategoryGroup = (category, index) => {
        const categoryOptions = serverData.categories?.map(cat => ({ value: cat.id.toString(), label: cat.category })) || [];
        const selectedCategories = Array.isArray(category.categories) ? category.categories : [];

        const needsBelts = selectedCategories.length > 0 && selectedCategories.some(catId =>
            serverData.categories_with_belt.some(beltCat => beltCat.category_id === parseInt(catId, 10))
        );

        const beltOptions = serverData.belts?.filter(belt => belt.id !== 0).map(belt => ({ value: belt.id.toString(), label: belt.belt })) || [];
        const selectedBelts = Array.isArray(category.belts) ? category.belts : [];

        const genderOptions = serverData.gender?.map(gen => ({ value: gen.id.toString(), label: gen.gender })) || [];
        const selectedGenders = Array.isArray(category.genders) ? category.genders : [];

        const styleOptions = serverData.styles?.map(style => ({ value: style.id.toString(), label: style.style })) || [];
        const selectedStyles = Array.isArray(category.styles) ? category.styles : [];

        return (
            <UCard
                key={index}
                className="mb-12"
                bodyClass="space-y-6 p-6"
            >
                <div className="flex justify-between items-center mb-4">
                    <div className="text-lg font-semibold">{t.categoryGroupN} {index + 1}</div>
                    <button
                        onClick={() => handleDeleteRow(index)}
                        className="text-red-500 hover:text-red-700"
                        aria-label="Delete row"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                        </svg>
                    </button>
                </div>

                <div className="relative">
                    <button
                        type="button"
                        onClick={() => toggleCategoryDropdown(index)}
                        className="w-full text-left bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm px-4 py-2 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                        {selectedCategories.length > 0
                            ? selectedCategories.map(catId => categoryOptions.find(opt => opt.value === catId)?.label).join(', ')
                            : `${t.categoriesSelect}`}
                    </button>
                    {openCategoryDropdowns[index] && (
                        <div
                            className="absolute mt-1 w-full z-10 bg-white dark:bg-gray-700 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {categoryOptions.map((cat) => (
                                <label key={cat.value}
                                       className="flex items-center px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-indigo-600"
                                        checked={selectedCategories.includes(cat.value)}
                                        onChange={() => handleCategoryChange(index, cat)}
                                    />
                                    <span className="ml-2 text-gray-900 dark:text-gray-200">{cat.label}</span>
                                </label>
                            ))}
                        </div>
                    )}
                </div>

                {needsBelts && (
                    <div className="relative">
                        <button
                            type="button"
                            onClick={() => toggleBeltDropdown(index)}
                            className="w-full text-left bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm px-4 py-2 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                        >
                            {selectedBelts.length > 0
                                ? selectedBelts.map(beltId => beltOptions.find(opt => opt.value === beltId)?.label).join(', ')
                                : `${t.beltsSelect}`}
                        </button>
                        {openBeltDropdowns[index] && (
                            <div
                                className="absolute mt-1 w-full z-10 bg-white dark:bg-gray-700 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {beltOptions.map((belt) => (
                                    <label key={belt.value}
                                           className="flex items-center px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600">
                                        <input
                                            type="checkbox"
                                            className="form-checkbox h-5 w-5 text-indigo-600"
                                            checked={selectedBelts.includes(belt.value)}
                                            onChange={() => handleBeltChange(index, belt)}
                                        />
                                        <span className="ml-2 text-gray-900 dark:text-gray-200">{belt.label}</span>
                                    </label>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                <div className='flex-grow'>
                    <UInput
                        className="min-w-[100%]"
                        label={t.categoryName}
                        placeholder={t.categoryNameAutomaticGeneration}
                        onChange={(event) => handleCategoryNameChange(index, event.target.value)}
                    />
                </div>
                <div className='flex'>
                    <div className="space-y-2">
                        <label
                            className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.selectGenders}</label>
                        {genderOptions.map((gender) => (
                            <label key={gender.value} className="flex items-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 text-indigo-600"
                                    checked={selectedGenders.includes(gender.value)}
                                    onChange={() => handleGenderChange(index, gender)}
                                />
                                <span className="ml-2 text-gray-900 dark:text-gray-200">{gender.label}</span>
                            </label>
                        ))}
                    </div>
                    <div className="space-y-2 ml-6">
                        <label
                            className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.selectStyles}</label>
                        {styleOptions.map((style) => (
                            <label key={style.value} className="flex items-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 text-indigo-600"
                                    checked={selectedStyles.includes(style.value)}
                                    onChange={() => handleStyleChange(index, style)}
                                />
                                <span className="ml-2 text-gray-900 dark:text-gray-200">{style.label}</span>
                            </label>
                        ))}
                    </div>
                </div>
                <div className="flex items-center space-x-4">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={category.add_everyone}
                            onChange={(e) => {
                                const newCategories = [...tournamentData.categories];
                                newCategories[index].add_everyone = e.target.checked;
                                setTournamentData(prev => ({...prev, categories: newCategories}));
                            }}
                            className="form-checkbox h-5 w-5 text-indigo-600"
                        />
                        <span className="ml-2 text-gray-700 dark:text-gray-300">{t.autoAdd}</span>
                    </label>
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={category.festival}
                            onChange={(e) => {
                                const newCategories = [...tournamentData.categories];
                                newCategories[index].festival = e.target.checked;
                                setTournamentData(prev => ({...prev, categories: newCategories}));
                            }}
                            className="form-checkbox h-5 w-5 text-indigo-600"
                        />
                        <span className="ml-2 text-gray-700 dark:text-gray-300">{t.festivalMode}</span>
                    </label>
                </div>
            </UCard>
        );
    };

    if (error) {
        return (
            <div className="container mx-auto px-4 py-8">
                <UCard>
                    <p className="text-red-500 text-center">{error}</p>
                </UCard>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <UCard
                title={t.createTournament}
                headerClass="flex justify-between items-center"
                headerExtra={<ThemeLanguageToggle/>}
                bodyClass="space-y-6"
            >
            {loading ? (
                <ULoading />
            ) : (
                <form onSubmit={handleSubmit} className="space-y-6">
                        <UInput
                            label={t.tournamentName}
                            name="name"
                            value={tournamentData.name}
                            onChange={handleInputChange}
                            required
                        />

                        <UInput
                            type="date"
                            label={t.tournamentDate}
                            name="date"
                            value={tournamentData.date}
                            onChange={handleInputChange}
                            required
                        />

                        <UInput
                            label={t.tournamentLocation}
                            name="location"
                            value={tournamentData.location}
                            onChange={handleInputChange}
                            required
                        />

                        <div>
                            <div className="text-lg font-medium text-gray-900 dark:text-white mb-4">Categorias</div>
                            <div className="space-y-6">
                                {tournamentData.categories.map((category, index) => renderCategoryGroup(category, index))}
                            </div>
                            <button
                                type="button"
                                onClick={() => setTournamentData(prev => ({
                                    ...prev,
                                    categories: [...prev.categories, { categories: [], add_everyone: false, festival: false }]
                                }))}
                                className="mt-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                {t.addCategoryButton}
                            </button>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                {t.createTournament}
                            </button>
                        </div>
                    </form>
                )}
            </UCard>
        </div>
    );
};
