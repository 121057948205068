import React, { useEffect, useState } from 'react';
import { UCard } from '../CustomComponents/UCard';
import { ULoading } from "../CustomComponents/ULoading";
import { EditProfile } from "./EditProfile";
import { getUser } from "../server/user/[...].user.get";
import { getFromLocalStorage, saveToLocalStorage } from "../utils/localStorageHandler";
import { useLanguage } from "../contexts/LanguageContext";
import {UButton} from "../CustomComponents/UButton";

const translations = {
    en: {
        myProfile: "My Profile",
        name: "Name",
        nickname: "Nickname",
        category: "Category",
        belt: "Belt",
        weight: "Weight",
        dob: "Date of Birth",
        gender: "Gender",
        male: "Male",
        female: "Female",
        nonBinary: "Non binary",
        state: "State",
        contactNumber: "Contact number",
        country: "Country",
        loading: "Loading...",
        editProfile: "Edit profile",
        saveChanges: "Save",
        cancel: "Cancel",
        profilePicture: "Profile picture",
    },
    pt: {
        myProfile: "Meu perfil",
        name: "Nome",
        nickname: "Apelido",
        category: "Categoria",
        belt: "Corda",
        weight: "Peso",
        dob: "Data de Nascimento",
        gender: "Gênero",
        male: "Masculino",
        female: "Feminino",
        nonBinary: "Não binário",
        state: "Estado",
        contactNumber: "Número de contato",
        country: "País",
        loading: "Carregando...",
        editProfile: "Editar",
        saveChanges: "Salvar",
        cancel: "Cancelar",
    }
};

export const ProfileItem = ({ label, value }) => (
    <div className="flex justify-between py-2 border-b border-gray-200 dark:border-gray-700">
        <span className="font-semibold text-gray-700 dark:text-gray-300">{label}:</span>
        <span className="text-gray-900 dark:text-gray-100">{value || 'N/A'}</span>
    </div>
);

export const Profile = () => {
    const [user, setUser] = useState(getFromLocalStorage('userProfile', null));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        if (user) return;
        fetchUser();
    });

    const fetchUser = async () => {
        setLoading(true);
        try {
            const userId = getFromLocalStorage('userId');
            const response = await getUser(userId);
            if (response) {
                setUser(response);
                saveToLocalStorage('userProfile', response);
            } else {
                throw new Error('No user data received');
            }
        } catch (error) {
            console.error('Failed to fetch user details:', error);
            setError('Failed to load user profile. Please try again later.');
        } finally {
            setLoading(false);
        }
    }

    const handleUpdateUser = (updatedUser) => {
        setUser(updatedUser);
        saveToLocalStorage('userProfile', updatedUser);
    }

    if (loading) {
        return (
            <div className="container mx-auto px-4 py-8 max-w-3xl flex justify-center items-center h-64">
                <ULoading size="lg" color="primary" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="container mx-auto px-4 py-8 max-w-3xl">
                <UCard>
                    <p className="text-red-500 text-center">{error}</p>
                </UCard>
            </div>
        );
    }

    if (!user) {
        return (
            <div className="container mx-auto px-4 py-8 max-w-3xl">
                <UCard>
                    <p className="text-gray-500 text-center">No user data available.</p>
                </UCard>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <UCard
                title={t.myProfile}
                bodyClass="space-y-4"
                footerClass="flex justify-center"
                footer={
                    <UButton className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark transition-colors" onClick={() => setIsEditing(true)}>
                        {t.editProfile}
                    </UButton>
                }
            >
                <div className="flex flex-col items-center mb-6">
                    {user.imageUrl && (
                        <img
                            src={user.imageUrl}
                            alt={`${user.name}'s profile`}
                            className="w-32 h-32 rounded-full mb-4"
                        />
                    )}
                    <div className="text-2xl font-bold text-gray-900 dark:text-white">{user.nickname}</div>
                    <p className="text-gray-600 dark:text-gray-400">{user.name}</p>
                </div>

                <ProfileItem label={t.contactNumber} value={user.phone} />
                <ProfileItem label={t.category} value={user.category} />
                <ProfileItem label={t.belt} value={user.belt} />
                <ProfileItem label={t.weight} value={`${user.weight} kg`} />
                <ProfileItem label={t.dob} value={user.dob} />
                <ProfileItem label={t.gender} value={user.gender} />
                <ProfileItem label={t.state} value={user.state} />
                <ProfileItem label={t.country} value={user.country} />
            </UCard>
            {isEditing && (

                <EditProfile
                    user={user}
                    onClose={() => setIsEditing(false)}
                    onUpdate={handleUpdateUser}
                />
            )}
        </div>
    );
};
