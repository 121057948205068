/*
 * Tomas Costantino
 *
 * Post new tournament
 * */

import axios from "axios";

export async function postTournament(payload) {
    return axios.post(`${process.env.REACT_APP_API_BASE}/tournament`, payload)
        .then(response => {
            return response.data;
        })
        .catch (error => {
            throw new Error(error.response.data.detail);
    })
}

export async function enrollToTournament(payload) {
    return axios.post(`${process.env.REACT_APP_API_BASE}/tournament/enroll`, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}

export async function confirmEnrollToTournament(payload) {
    return axios.post(`${process.env.REACT_APP_API_BASE}/tournament/enroll/confirmation`, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}
