import React, { useState, useEffect } from 'react';
import { getTermsAndConditions } from "../server/terms-and-conditions/[...].handler";
import { UCard } from '../CustomComponents/UCard';
import { useLanguage } from "../contexts/LanguageContext";
import { ULoading } from "../CustomComponents/ULoading";
import DOMPurify from 'dompurify';

const translations = {
    en: {
        termsAndConditions: "Terms and Conditions",
        loading: "Loading...",
        error: "Error loading Terms and Conditions. Please try again later.",
    },
    pt: {
        termsAndConditions: "Termos e Condições",
        loading: "Carregando...",
        error: "Erro ao carregar os Termos e Condições. Por favor, tente novamente mais tarde.",
    }
};

const TermsContent = ({ content }) => {
    const createMarkup = (html) => {
        const sanitizedHtml = DOMPurify.sanitize(html);
        const formattedHtml = sanitizedHtml.replace(
            /<div class="policy-detail[^>]*>([\s\S]*?)<\/div>/g,
            (match, p1) => {
                const trimmedContent = p1.trim().split('\n').map((line, index, array) => {
                    if (index === 0 || index === array.length - 1) {
                        return line.trim();
                    }
                    return line;
                }).join('\n');

                const escapedContent = trimmedContent
                    .replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&#039;')
                    .replace(/\n/g, '<br>')
                    .replace(/\s{2,}/g, match => '&nbsp;'.repeat(match.length));
                return `<div class="policy-detail text-gray-600 dark:text-gray-400 whitespace-pre-wrap">${escapedContent}</div>`;
            }
        );
        return { __html: formattedHtml };
    };

    return (
        <div
            className="terms-content prose dark:prose-invert max-w-none"
            dangerouslySetInnerHTML={createMarkup(content)}
        />
    );
};

export const TermsAndConditions = () => {
    const [template, setTemplate] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const response = await getTermsAndConditions();
                setTemplate(response);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchTemplate();
    }, []);

    if (loading) {
        return <ULoading />;
    }

    if (error) {
        return (
            <div className="container mx-auto p-4 max-w-3xl">
                <UCard
                    title={t.termsAndConditions}
                    bodyClass="text-center text-red-500 dark:text-red-400"
                >
                    {t.error}
                </UCard>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-4 max-w-3xl">
            <UCard
                title={t.termsAndConditions}
                bodyClass="space-y-4"
            >
                <TermsContent content={template} />
            </UCard>
        </div>
    );
};
