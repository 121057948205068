/**
 * Tomas Costantino
 * 
 * Retrieve user user data from server
 */

import axios from "axios";

export async function patchUser(userId, payload) {
    return axios.patch(`${process.env.REACT_APP_API_BASE}/user/${userId}`, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error);
        })
}

export async function patchUserPermissions(payload) {
    return axios.patch(`${process.env.REACT_APP_API_BASE}/user-permissions`, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error);
        })
}


export async function reactivateUser(userId) {
    return axios.patch(`${process.env.REACT_APP_API_BASE}/user/${userId}/reactivate`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error);
        })
}