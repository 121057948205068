/**
 *  Tomas Costantino
 *
 *  Tie breaker
 */

import axios from "axios";

export async function getTies() {
    return axios.get(`${process.env.REACT_APP_API_BASE}/ties`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error('Server fetch error', error);
        })
}


export async function submitTieBreaker(data) {
    return axios.post(`${process.env.REACT_APP_API_BASE}/ties`, data)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error('Server fetch error', error);
        })
}
