import React, { useEffect, useState} from 'react';
import { UCard } from '../CustomComponents/UCard';
import { ULoading } from "../CustomComponents/ULoading";
import { getFromLocalStorage } from "../utils/localStorageHandler";
import { getMatchesForTournamentIdByUserId } from "../server/match/[...].match.get";
import { getFinishedTournaments } from "../server/tournament/[...].tournament.get";
import { useLanguage } from "../contexts/LanguageContext";
import { USelect } from "../CustomComponents/USelect";

const translations = {
    en: {
        selectTournament: "Select Tournament",
        title: "My Matches",
        noMatch: "No match scores available.",
        loading: "Loading..."
    },
    pt: {
        selectTournament: "Selecione o Torneio",
        title: "Minhas Partidas",
        noMatch: "Nenhuma pontuação disponível.",
        loading: "Carregando..."
    }
};

const defaultOptions = ['Technica', 'Objectividade', 'Continuidade', 'Criatividade', 'Caracterizacao'];

const parseDiscounts = (discountString) => {
    if (!discountString) return {};
    try {
        return Object.fromEntries(
            discountString.split(') (').map(item => {
                const [key, value] = item.replace(/[()]/g, '').split(',');
                return [key, parseFloat(value)];
            })
        );
    } catch (error) {
        console.error('Error parsing discounts:', error);
        return {};
    }
};

const ReadOnlyJudgeForm = ({ match }) => {
    if (!match) return null;

    const discountsPlayer1 = parseDiscounts(match.discount_player1);
    const discountsPlayer2 = parseDiscounts(match.discount_player2);

    return (
        <UCard className="mb-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
            <div className="bg-gray-200 dark:bg-darkMode-lighter p-4 rounded-md">
                <div className="flex flex-col items-center space-y-2 text-sm">
                    <span className='text-xl'><strong>Round:</strong> {match.round || 'N/A'}</span>
                    <span className='text-xl'><strong>Style:</strong> {match.style || 'N/A'}</span>
                </div>
            </div>
            <div className="p-4">
                <div className="sm:flex sm:justify-between sm:items-center mb-4">
                    {[
                        { name: match.player1_nickname, total: match.total_player1, discounts: discountsPlayer1 },
                        { name: match.player2_nickname, total: match.total_player2, discounts: discountsPlayer2 }
                    ].map((player, index) => (
                        <div key={index} className="mb-4 sm:mb-0 w-full sm:w-[48%]">
                            <div className="text-lg font-semibold bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-between">
                                <span>{player.name || `Player ${index + 1}`}</span>
                                <span className="ml-2 bg-white text-blue-500 px-2 py-1 rounded-full text-sm">
                                    {player.total !== undefined ? player.total.toFixed(1) : 'N/A'}
                                </span>
                            </div>
                            <div className="mt-2 space-y-2">
                                {defaultOptions.map(option => (
                                    <div key={`Player${index + 1}-${option}`} className="space-y-1">
                                        <label className="flex justify-between">
                                            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">{option}</span>
                                            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                                {player.discounts[option] !== undefined ? player.discounts[option].toFixed(1) : 'N/A'}
                                            </span>
                                        </label>
                                        <div className="w-full h-2 bg-gray-200 rounded-lg dark:bg-gray-700">
                                            <div
                                                className="h-full bg-blue-500 rounded-lg"
                                                style={{width: `${Math.min((player.discounts[option] || 0) * 100, 100)}%`}}
                                            ></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col items-center justify-center my-4">
                    <label className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Match Score:</label>
                    <span className="text-xl font-bold">{match.score || 'N/A'}</span>
                </div>
                {match.highlighted_match === 1 && (
                    <div className="flex items-center justify-center mt-4">
                        <span className="px-2 py-1 bg-yellow-200 text-yellow-800 rounded-full text-sm">Highlighted Match</span>
                    </div>
                )}
            </div>
        </UCard>
    );
};


export const Matches = () => {
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(null);
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        fetchTournaments();
    }, []);

    useEffect(() => {
        if (selectedTournament) {
            fetchMatches(selectedTournament.tournament_category_id);
        }
    }, [selectedTournament]);

    const fetchTournaments = async () => {
        setLoading(true);
        try {
            const response = await getFinishedTournaments(getFromLocalStorage('userId', 0));
             setTournaments(response);
             setSelectedTournament(response[0]);
        } catch (error) {
            console.error('Failed to fetch tournaments:', error);
            setError('Failed to load tournaments. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const fetchMatches = async (tournamentId) => {
        setLoading(true);
        try {
            const fetchedData = await getMatchesForTournamentIdByUserId(getFromLocalStorage('userId', 0), tournamentId);
            setMatches(fetchedData);
        } catch (error) {
            console.error('Failed to fetch matches:', error);
            setError('Failed to load matches. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleTournamentSelectChange = (tournament_category_id) => {
        const selectedTournament = tournaments.find((tournament) => tournament.tournament_category_id === tournament_category_id);
        setSelectedTournament(selectedTournament);
    };
    const tournamentOptions = tournaments.map(t => ({
        value: t.tournament_category_id,
        label: t.tournament_name
    }));

    if (error) {
        return (
            <div>
                {error}
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <UCard
                title={t.title}
                bodyClass="space-y-4"
            >
                {loading ? (
                    <ULoading />
                ) : (
                    <>
                        {tournaments.length > 0 && (
                            <div className="mb-4">
                                <h3 className="text-xl justify-center text-center font-semibold mb-2">{t.selectTournament}</h3>
                                <USelect
                                    options={tournamentOptions}
                                    value={selectedTournament.tournament_category_id}
                                    onChange={handleTournamentSelectChange}
                                    placeholder={t.selectTournament}
                                />
                                <h3 className="text-lg justify-center text-center mt-2 mb-2">Categoria: {selectedTournament.tournament_category_name}</h3>
                            </div>
                        )}

                        {matches.length > 0 ? (
                            <div className="space-y-4">
                                {matches.map((match, index) => (
                                    <ReadOnlyJudgeForm key={index} match={match} />
                                ))}
                            </div>
                        ) : (
                            <p className="text-center text-gray-500">{t.noMatch}</p>
                        )}
                    </>
                )}
            </UCard>
        </div>
    );
};
