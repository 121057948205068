/**
 * Tomas Costantino
 *
 * Server module for tournament settings
 */

import axios from "axios";

export async function updateTournament(tournamentId, payload) {
    return axios.patch(`${process.env.REACT_APP_API_BASE}/tournament/by-tournament-id/${tournamentId}`, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error('Server request failed', error);
        })
}

export async function updateTournamentStatus(tournamentId, status) {
    return axios.patch(`${process.env.REACT_APP_API_BASE}/tournament/status`, { tournament: tournamentId })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}


export async function updateTournamentDraw(tournamentId, groups, participants, availablePlayers, judges, qualifiers) {
    return axios.patch(`${process.env.REACT_APP_API_BASE}/tournament/by-tournament-id/${tournamentId}/draw`,
        { groups: groups, available: availablePlayers, round: 1, judges: judges, participants: participants, qualifiers: qualifiers || false })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}
