import React, { useState, useEffect, useCallback, useRef } from 'react';
import { UCard } from '../CustomComponents/UCard';
import { USelect } from '../CustomComponents/USelect';
import { updateScore } from "../server/judge/[...].score.patch.";
import toastr from 'toastr';
import { UModal } from "../CustomComponents/UModal";
import { getJudgeTournaments, getJudgeMatchesForTournament } from "../server/judge/[...].tournament.get";
import { UInput } from "../CustomComponents/UInput";
import { UButton } from "../CustomComponents/UButton";
import { getFromLocalStorage } from "../utils/localStorageHandler";
import { ULoading } from "../CustomComponents/ULoading";

import { useLanguage } from "../contexts/LanguageContext";

const translations = {
    en: {
        judge: "Judge",
        selectTournamentCategory: "Select tournament category",
        noTournaments: "No tournaments available for judging.",
        matchesToJudge: "Matches to judge",
        noMatches: "No matches available for this tournament category.",
        loadError: "Failed to load. Please try again later.",
        matchScore: "Match Score:",
        highlightMatch: "Highlight match",
        submit: "SUBMIT",
        submitting: "Submitting...",
        round: "Round:",
        group: "Group:",
        style: "Style:",
        scoreWarning: "Match score must be between 0 and 5",
        enterScore: "Please enter the match score",
        scoreSuccess: "Score submitted successfully",
        scoreFail: "Failed to submit score",
        serverError: "Server error",
        confirmSubmission: "Confirm Submission",
        confirmScoreMessage: "Are you sure you want to submit this score? This action cannot be undone.",
        cancel: "Cancel",
        confirm: "Confirm",
    },
    pt: {
        judge: "Juiz",
        selectTournamentCategory: "Selecione a categoria do torneio",
        noTournaments: "Não há torneios disponíveis para julgar.",
        matchesToJudge: "Partidas para julgar",
        noMatches: "Não há partidas disponíveis para esta categoria de torneio.",
        loadError: "Falha ao carregar. Por favor, tente novamente mais tarde.",
        matchScore: "Pontuação da Partida:",
        highlightMatch: "Destacar partida",
        submit: "ENVIAR",
        submitting: "Enviando...",
        round: "Rodada:",
        group: "Grupo:",
        style: "Estilo:",
        scoreWarning: "A pontuação da partida deve estar entre 0 e 5",
        enterScore: "Por favor, insira a pontuação da partida",
        scoreSuccess: "Pontuação enviada com sucesso",
        scoreFail: "Falha ao enviar a pontuação",
        serverError: "Erro no servidor",
        confirmSubmission: "Confirmar Envio",
        confirmScoreMessage: "Tem certeza de que deseja enviar esta pontuação? Esta ação não pode ser desfeita.",
        cancel: "Cancelar",
        confirm: "Confirmar",
    }
};

const styles_color_mapping = [
    { style_id: 1, color: 'bg-blue-200 dark:bg-blue-700' },
    { style_id: 2, color: 'bg-green-200 dark:bg-green-700' },
    { style_id: 3, color: 'bg-purple-200 dark:bg-purple-700' },
]

export const Judge = () => {
    const [tournamentCategories, setTournamentCategories] = useState(null);
    const [selectedTournamentCategory, setSelectedTournamentCategory] = useState(null);
    const [matches, setMatches] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const contentRef = useRef(null);

    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
    }, [matches]);

    useEffect(() => {
        fetchTournaments();
    }, []);

    useEffect(() => {
        if (selectedTournamentCategory) {
            fetchMatches(selectedTournamentCategory.tournament_category_id);
        }
    }, [selectedTournamentCategory]);

    const fetchTournaments = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const userId = localStorage.getItem('userId') || 1;
            const response = await getJudgeTournaments(userId);
            setTournamentCategories(response);
            if (response && response.length > 0) {
                setSelectedTournamentCategory(response[0]);
            }
        } catch (error) {
            console.error("Failed to fetch tournaments:", error);
            setError("Failed to load tournament categories. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchMatches = async (tournamentCategoryId) => {
        setIsLoading(true);
        setError(null);
        try {
            const userId = localStorage.getItem('userId') || 1;
            const response = await getJudgeMatchesForTournament(userId, tournamentCategoryId);
            setMatches(response.data);
        } catch (error) {
            console.error("Failed to fetch matches:", error);
            setError("Failed to load matches. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleTournamentChange = (tournamentId) => {
        const tournament = tournamentCategories.find(t => t.tournament_category_id === tournamentId);
        setSelectedTournamentCategory(tournament);
    };

    const renderContent = () => {
        if (isLoading) {
            return (
                <ULoading />
            );
        }

        if (error) {
            return <div className="text-red-500 dark:text-red-400">{t.loadError}</div>;
        }

        if (!tournamentCategories || tournamentCategories.length === 0) {
            return <p className="text-text-DEFAULT dark:text-text-dark">{t.noTournaments}</p>;
        }

        const tournamentOptions = tournamentCategories.map(tournament => ({
            value: tournament.tournament_category_id,
            label: tournament.tournament_category_name
        }));

        const removeMatch = (matchIdToRemove) => {
            setMatches(currentMatches => currentMatches.filter(match => match.match_id !== matchIdToRemove));
        };

        return (
            <>
                <div className="flex flex-col items-center">
                    <label htmlFor="tournament-select" className="text-lg font-semibold text-text-DEFAULT dark:text-text-dark mb-2 text-center">
                        {t.selectTournamentCategory}
                    </label>
                    <div className="w-full max-w-md">
                        <USelect
                            options={tournamentOptions}
                            value={selectedTournamentCategory?.tournament_category_id}
                            onChange={handleTournamentChange}
                            placeholder={t.selectTournamentCategory}
                        />
                    </div>
                </div>

                <div className="mt-8">
                    <h3 className="text-lg text-center font-semibold text-text-DEFAULT dark:text-text-dark mb-4">{t.matchesToJudge}</h3>
                    {matches.length > 0 ? (
                        matches.map((match, index) => (
                            <div className="mb-6" key={`${match.match_id}-${index}`}>
                                <JudgeForm
                                    key={match.match_id}
                                    match={match}
                                    onRemove={() => removeMatch(match.match_id)}
                                    t={t}
                                />
                            </div>
                        ))
                    ) : (
                        <p className="text-text-light dark:text-text-dark">{t.noMatches}</p>
                    )}
                </div>
            </>
        );
    };

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <div className="w-full max-w-3xl">
                <UCard
                    title={t.judge}
                    headerClass="flex justify-between items-center"
                    bodyClass="space-y-6"
                >
                    {renderContent()}
                </UCard>
            </div>
        </div>
    );
};


const JudgeForm = ({match, onRemove, t}) => {
    const defaultOptions = ['Technica', 'Objectividade', 'Continuidade', 'Criatividade', 'Caracterizacao'];
    const [localMatch, setLocalMatch] = useState({
        ...match,
        availableOptions: match.availableOptions || defaultOptions,
        optionScoresPlayerOne: Object.fromEntries(defaultOptions.map(option => [option, 0])),
        optionScoresPlayerTwo: Object.fromEntries(defaultOptions.map(option => [option, 0])),
    });
    const [matchScore, setMatchScore] = useState('');
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalScores, setTotalScores] = useState({ playerOne: 0, playerTwo: 0 });
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const updateTotalScores = useCallback(() => {
        const calculatePlayerScore = (playerScores) => {
            const demerits = Object.values(playerScores).reduce((sum, score) => sum + score / 10, 0);
            return Math.max(0, Number(matchScore) - demerits).toFixed(2);
        };

        setTotalScores({
            playerOne: calculatePlayerScore(localMatch.optionScoresPlayerOne),
            playerTwo: calculatePlayerScore(localMatch.optionScoresPlayerTwo)
        });
    }, [localMatch, matchScore]);

    useEffect(() => {
        updateTotalScores();
    }, [updateTotalScores]);

    useEffect(() => {
        setLocalMatch(prevState => ({ ...prevState, highlighted_match: isHighlighted}));
    }, [isHighlighted]);

    const handleSliderChange = (player, option, value) => {
        setLocalMatch(prevMatch => ({
            ...prevMatch,
            [`optionScores${player}`]: {
                ...prevMatch[`optionScores${player}`],
                [option]: Number(value)
            }
        }));
    };

    const handleMatchScoreChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && Number(value) >= 0 && Number(value) <= 5) {
            setMatchScore(value);
        } else {
            toastr.warning(t.scoreWarning);
        }
    };

    const handleSubmit = () => {
        if (!matchScore || matchScore === '') {
            toastr.error(t.enterScore);
            return;
        }
        setIsConfirmModalOpen(true);
    };

    const handleConfirmedSubmit = async () => {
        setIsConfirmModalOpen(false);
        setIsLoading(true);

        try {
            const updatedMatch = {
                ...localMatch,
                matchScore,
                totalScores,
            };
            const judge = getFromLocalStorage('userId', 0);
            const response = await updateScore(judge, match.match_id, updatedMatch);

            if (response) {
                toastr.success(t.scoreSuccess);
                onRemove();
            } else {
                toastr.warning(t.scoreFail);
            }
        } catch (error) {
            toastr.error(t.serverError);
        } finally {
            setIsLoading(false);
        }
    };

    const handleStyleColor = () => {
        const foundStyle = styles_color_mapping.find((style) => style.style_id === match.style_id);
        return foundStyle ? foundStyle.color : '';
    }

    return (
        <UCard>
            <div className={`${handleStyleColor()} p-4 rounded-md`}>
                <div className="flex flex-col items-center space-y-2 text-sm">
                    <span className='text-xl'><strong>{t.round}</strong> {match.round}</span>
                    {match.group > 0 && (
                        <span className='text-xl'><strong>{t.group}</strong> {match.group}</span>
                    )}
                    <span className='text-xl'><strong>{t.style}</strong> {match.style}</span>
                </div>
            </div>
            <div className="p-4">
                <div className="flex flex-col items-center justify-center mb-4">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">{t.matchScore}</label>
                <UInput
                        type="number"
                        className="form-input w-20 text-center"
                        value={matchScore}
                        onChange={handleMatchScoreChange}
                        min="0"
                        max="5"
                        step="1"
                    />
                </div>
                <div className="flex flex-col sm:flex-row sm:justify-between sm:space-x-4">
                    <div className="w-full sm:w-1/2 mb-4 sm:mb-0">
                        <div
                            className="text-lg font-semibold bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-between">
                            <span>{match.player1_nickname}</span>
                            <span className="ml-2 bg-white text-blue-500 px-2 py-1 rounded-full text-sm">
                            {totalScores.playerOne}
                        </span>
                        </div>
                        <div className="mt-2 space-y-2">
                            {defaultOptions.map(option => (
                                <div key={`PlayerOne-${option}`} className="space-y-1">
                                    <label className="flex justify-between">
                                    <span
                                        className="text-sm font-medium text-gray-700 dark:text-gray-300">{option}</span>
                                        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                        {localMatch.optionScoresPlayerOne[option] / 10}
                                    </span>
                                    </label>
                                    <input
                                        type="range"
                                        min="0"
                                        max="10"
                                        value={localMatch.optionScoresPlayerOne[option]}
                                        onChange={(e) => handleSliderChange('PlayerOne', option, e.target.value)}
                                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                        <div
                            className="text-lg font-semibold bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-between">
                            <span>{match.player2_nickname}</span>
                            <span className="ml-2 bg-white text-blue-500 px-2 py-1 rounded-full text-sm">
                            {totalScores.playerTwo}
                        </span>
                        </div>
                        <div className="mt-2 space-y-2">
                            {defaultOptions.map(option => (
                                <div key={`PlayerTwo-${option}`} className="space-y-1">
                                    <label className="flex justify-between">
                                    <span
                                        className="text-sm font-medium text-gray-700 dark:text-gray-300">{option}</span>
                                        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                        {localMatch.optionScoresPlayerTwo[option] / 10}
                                    </span>
                                    </label>
                                    <input
                                        type="range"
                                        min="0"
                                        max="10"
                                        value={localMatch.optionScoresPlayerTwo[option]}
                                        onChange={(e) => handleSliderChange('PlayerTwo', option, e.target.value)}
                                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center my-4">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-blue-600 rounded"
                            checked={isHighlighted}
                            onChange={(e) => setIsHighlighted(e.target.checked)}
                        />
                        <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">{t.highlightMatch}</span>
                    </label>
                </div>
                <UButton
                    className="w-full px-4 py-2 bg-primary hover:bg-primary-dark"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    {isLoading ? t.submitting : t.submit}
                </UButton>
                <UModal
                    isOpen={isConfirmModalOpen}
                    onClose={() => setIsConfirmModalOpen(false)}
                    title={t.confirmSubmission}
                >
                    <p className="mb-4">{t.confirmScoreMessage}</p>
                    <div className="flex justify-end space-x-2">
                        <UButton
                            onClick={() => setIsConfirmModalOpen(false)}
                            className="bg-red-500 hover:bg-red-700"
                        >
                            {t.cancel}
                        </UButton>
                        <UButton
                            onClick={handleConfirmedSubmit}
                            className="bg-primary hover:bg-primary-dark"
                        >
                            {t.confirm}
                        </UButton>
                    </div>
                </UModal>
            </div>
        </UCard>
    );
};
