/**
 * Tomas Costantino
 *
 * Judge screen score get
 */

import axios from 'axios';

export async function getUpcomingMatches(tournamentId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/match/upcoming/by-tournament-id/${tournamentId}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error);
        })
}

export async function getJudgeScores(tournamentId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/judge/scores/by-tournament-id/${tournamentId}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error);
        })
}

export async function getMatchScores(tournamentId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/match/scores/by-tournament-id/${tournamentId}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error);
        })
}
