import React from 'react';

export const UButton = ({
                            children,
                            onClick,
                            type = 'button',
                            variant = 'primary',
                            size = 'md',
                            disabled = false,
                            className = '',
                            ...props
                        }) => {
    const baseClasses = 'font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-out';

    const variantClasses = {
        primary: 'bg-primary-DEFAULT hover:bg-primary-dark text-white focus:ring-primary-DEFAULT',
        secondary: 'bg-secondary-DEFAULT hover:bg-secondary-dark text-text-DEFAULT dark:text-text-dark focus:ring-secondary-DEFAULT',
        danger: 'bg-red-600 hover:bg-red-700 text-white focus:ring-red-500',
    };

    const sizeClasses = {
        sm: 'px-2 py-1 text-sm',
        md: 'px-4 py-2',
        lg: 'px-6 py-3 text-lg',
    };

    const disabledClasses = 'opacity-50 cursor-not-allowed';

    const buttonClasses = `
        ${baseClasses}
        ${variantClasses[variant]}
        ${sizeClasses[size]}
        ${disabled ? disabledClasses : ''}
        ${className}
    `;

    return (
        <button
            type={type}
            className={buttonClasses}
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            {children}
        </button>
    );
};
