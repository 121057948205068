/**
 * Tomas Costantino
 * 
 * Retrieve user user data from server
 */

import axios from "axios";

export async function getUser(userId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/user/${userId}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        });
}

export async function getUsers() {
    return axios.get(`${process.env.REACT_APP_API_BASE}/user`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}


export async function getUserPermissions() {
    return axios.get(`${process.env.REACT_APP_API_BASE}/user-permissions`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}

export async function getProfilePhoto(userId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/user/${userId}/photo`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error('Server fetch error', error);
        })
}
