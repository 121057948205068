/**
 * Tomas Costantino
 *
 * Judge screen score post
 */

import axios from 'axios';

export async function getJudgeTournaments(user_id) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/judge/${user_id}/tournament`, )
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return error.response.data.detail;
        })
}

export async function getJudgeMatchesForTournament(user_id, tournament_id) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/judge/${user_id}/matches/${tournament_id}`)
}
