/**
 * Tomas Costantino
 *
 * Reset password server interaction
 */

import axios from "axios";

export function resetPassword (data) {
    return axios.patch(`${process.env.REACT_APP_API_BASE}/reset-password`, data)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error);
        })
}
