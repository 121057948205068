import React from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from "../UIComponents/Footer";

export const NonAuthenticatedLayout = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <main className="flex-grow container mx-auto px-4 py-8">
                <Outlet />
            </main>
            <Footer />
        </div>
    );
};