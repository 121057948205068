import React, { useEffect, useRef } from 'react';
import { UButton } from './UButton';

export const UModal = ({ isOpen, onClose, children, title }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleEscape);
            document.addEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.removeEventListener('keydown', handleEscape);
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'unset';
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
            <div
                className="relative p-8 bg-background-light dark:bg-darkMode-light w-full max-w-md m-auto flex-col flex rounded-lg"
                ref={modalRef}
            >
                <div className="flex justify-between items-center pb-3">
                    {title && <h2 className="text-2xl font-bold text-text-DEFAULT dark:text-text-dark">{title}</h2>}
                    <UButton
                        onClick={onClose}
                        variant="secondary"
                        size="sm"
                        className="rounded-full"
                    >
                        ×
                    </UButton>
                </div>
                <div className="modal-body text-text-DEFAULT dark:text-text-dark">
                    {children}
                </div>
            </div>
        </div>
    );
};
