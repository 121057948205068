import React, { useState, useEffect, useCallback } from 'react';
import { UCard } from '../CustomComponents/UCard';
import { UButton } from "../CustomComponents/UButton";
import { ULoading } from '../CustomComponents/ULoading';
import { getTies, submitTieBreaker } from "../server/ties/[...].ties";
import toastr from 'toastr';
import { useLanguage } from "../contexts/LanguageContext";

const translations = {
    en: {
        title: "Tie Breakers",
        noTies: "No ties to resolve at the moment.",
        playersToSelect: "Players to select:",
        submit: "Submit Selection",
        error: "Error: Unable to fetch data from the server.",
        success: "Tie breaker submitted successfully.",
        selectPlayers: "Please select the required number of players."
    },
    pt: {
        title: "Desempates",
        noTies: "Não há empates para resolver no momento.",
        playersToSelect: "Jogadores a selecionar:",
        submit: "Enviar Seleção",
        error: "Erro: Não foi possível obter dados do servidor.",
        success: "Desempate enviado com sucesso.",
        selectPlayers: "Por favor, selecione o número necessário de jogadores."
    }
};

const TieBreaker = ({ tie, onSubmit }) => {
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const { language } = useLanguage();
    const t = translations[language];

    const handlePlayerSelect = (playerId) => {
        if (selectedPlayers.includes(playerId)) {
            setSelectedPlayers(selectedPlayers.filter(id => id !== playerId));
        } else if (selectedPlayers.length < tie.to_select) {
            setSelectedPlayers([...selectedPlayers, playerId]);
        }
    };

    const handleSubmit = () => {
        if (selectedPlayers.length === tie.to_select) {
            onSubmit(tie.tournament_round_id, selectedPlayers);
        } else {
            toastr.warning(t.selectPlayers);
        }
    };

    return (
        <UCard
            title={tie.tournament_category_name}
            className="mb-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden"
        >
            <div className="p-4">
                <h3 className="text-lg font-semibold mb-2">{t.playersToSelect} {tie.to_select}</h3>
                <div className="space-y-2">
                    {tie.tied_players.map(player => (
                        <div key={player.user_id} className="flex items-center">
                            <input
                                type="checkbox"
                                id={`player-${player.user_id}`}
                                checked={selectedPlayers.includes(player.user_id)}
                                onChange={() => handlePlayerSelect(player.user_id)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                            />
                            <label htmlFor={`player-${player.user_id}`} className="ml-2 text-gray-700 dark:text-gray-300">
                                {player.nickname}
                            </label>
                        </div>
                    ))}
                </div>
                <UButton
                    className="w-full mt-4 bg-primary hover:bg-primary-dark"
                    onClick={handleSubmit}
                >
                    {t.submit}
                </UButton>
            </div>
        </UCard>
    );
};

export const Ties = () => {
    const [ties, setTies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { language } = useLanguage();
    const t = translations[language];

    const fetchTies = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await getTies();
            setTies(response.ties);
        } catch (error) {
            console.error("Failed to fetch ties:", error);
            setError(t.error);
        } finally {
            setIsLoading(false);
        }
    }, [t.error]);

    useEffect(() => {
        fetchTies();
    }, [fetchTies]);

    const handleTieBreakerSubmit = async (tournamentRoundId, selectedPlayers) => {
        setIsLoading(true);
        try {
            await submitTieBreaker({ tournament_round_id: tournamentRoundId, selected_players: selectedPlayers });
            toastr.success(t.success);
            fetchTies(); // Refresh the ties list
        } catch (error) {
            console.error("Failed to submit tie breaker:", error);
            toastr.error(t.error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <UCard
                title={t.title}
                headerClass="flex justify-between items-center"
                bodyClass="space-y-6"
            >
                {isLoading ? (
                    <ULoading />
                ) : error ? (
                    <div className="text-red-500 dark:text-red-400">{error}</div>
                ) : ties.length > 0 ? (
                    ties.map((tie, index) => (
                        <TieBreaker
                            key={`${tie.tournament_round_id}-${index}`}
                            tie={tie}
                            onSubmit={handleTieBreakerSubmit}
                        />
                    ))
                ) : (
                    <p className="text-center text-gray-500 dark:text-gray-400">{t.noTies}</p>
                )}
            </UCard>
        </div>
    );
};