/**
 * Tomas Costantino
 */

export const categoryColors = {
    '1': 'K',
    '2': 'E1',
    '3': 'E2',
    '4': 'E3',
    '5': 'G 🔵 V',
    '6': 'G 🔵 M',
    '7': 'G 🔵 G',
    '8': 'G 🔵 A',
    '9': 'G 🔵🟢 V',
    '10': 'G 🔵🟢 M',
    '11': 'G 🔵🟢 G',
    '12': 'G 🔵🟢 A',
    '13': 'G 🟢 V',
    '14': 'G 🟢 M',
    '15': 'G 🟢 G',
    '16': 'G 🟢 A',
    '17': 'G 🟢🟣 V',
    '18': 'G 🟢🟣 M',
    '19': 'G 🟢🟣 G',
    '20': 'G 🟢🟣 A',
    '21': 'G 🟣 V',
    '22': 'G 🟣 M',
    '23': 'G 🟣 G',
    '24': 'G 🟣 A',
    '25': 'G 🟣🟤 V',
    '26': 'G 🟣🟤 M',
    '27': 'G 🟣🟤 G',
    '28': 'G 🟣🟤 A',
    '29': 'Baoba',
    '30': 'Prof 🟤',
    '31': 'Prof 🟤🔴',
    '32': 'Maestrandos',















}