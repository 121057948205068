/**
 * Tomas Costantino
 *
 * Server module for tournament settings
 */

import axios from "axios";

export async function deleteTournament(tournamentId) {
    return axios.delete(`${process.env.REACT_APP_API_BASE}/tournament/by-tournament-id/${tournamentId}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error('Server request failed', error);
        })
}
