import React, { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { getFromLocalStorage, saveToLocalStorage } from "../utils/localStorageHandler";

export const ThemeLanguageToggle = () => {
    const { language, toggleLanguage } = useLanguage();
    const [theme, setTheme] = useState(() => getFromLocalStorage('theme', 'light'));
    const [isChanging, setIsChanging] = useState(false);

    useEffect(() => {
        document.documentElement.classList.remove('light', 'dark');
        document.documentElement.classList.add(theme);
        saveToLocalStorage('theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
    };

    const handleLanguageToggle = () => {
        setIsChanging(true);
        setTimeout(() => {
            toggleLanguage();
            setIsChanging(false);
        }, 10);
    };

    return (
        <div className="flex space-x-2">
            <button
                onClick={toggleTheme}
                className="p-2 rounded-full bg-darkMode-lighter"
                aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
            >
                {theme === 'light' ? '🌙' : '☀️'}
            </button>
            <button
                onClick={handleLanguageToggle}
                className={`p-2 rounded-full bg-darkMode-lighter`}
                aria-label={`Switch to ${language === 'en' ? 'Portuguese' : 'English'}`}
                disabled={isChanging}
            >
                {language === 'en' ? '🇧🇷' : '🇬🇧'}
            </button>
        </div>
    );
};