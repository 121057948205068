import React, { createContext, useState, useContext, useEffect } from 'react';
import { getFromLocalStorage, saveToLocalStorage } from "../utils/localStorageHandler";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(() => getFromLocalStorage('language', 'en'));

    useEffect(() => {
        saveToLocalStorage('language', language);
    }, [language]);

    const toggleLanguage = () => {
        setLanguage(prevLang => prevLang === 'en' ? 'pt' : 'en');
    };

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);