/**
 * Tomas Costantino
 *
 * Judge screen score post
 */

import axios from 'axios';

export async function updateScore(judge_id, match_id, payload) {
    return axios.patch(`${process.env.REACT_APP_API_BASE}/judge/${judge_id}/match/${match_id}/score`, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return error.response.data.detail;
        })
}
