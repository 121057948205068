import React, { useState, useEffect } from 'react';
import { UCard } from '../CustomComponents/UCard';
import { USelect } from '../CustomComponents/USelect';
import { ULoading } from '../CustomComponents/ULoading';
import { getAvailableTournamentsForUserId } from "../server/tournament/[...].tournament.get";
import { enrollToTournament } from "../server/tournament/[...].tournament.post";
import { getFromLocalStorage } from "../utils/localStorageHandler";
import { useLanguage } from "../contexts/LanguageContext";
import toastr from "toastr";

const translations = {
    en: {
        findTournaments: "Find Tournaments",
        noTournaments: "No tournaments available at the moment.",
        selectTournament: "Select a Tournament",
        tournamentDetails: "Tournament Details",
        name: "Name:",
        date: "Date:",
        location: "Location:",
        categories: "Categories",
        belts: "Belts",
        styles: "Styles",
        enroll: "Request to enroll",
        enrollSuccess: "Request submitted successfully.",
        enrollError: "Failed to enroll. Please try again.",
        fetchError: "Failed to fetch tournaments. Please try again later.",
    },
    pt: {
        findTournaments: "Encontrar Torneios",
        noTournaments: "Nenhum torneio disponível no momento.",
        selectTournament: "Selecione um Torneio",
        tournamentDetails: "Detalhes do Torneio",
        name: "Nome:",
        date: "Data:",
        location: "Local:",
        categories: "Categorias",
        belts: "Cordas",
        styles: "Estilos",
        enroll: "Solicitação de inscrição",
        enrollSuccess: "Solicitação enviada com sucesso.",
        enrollError: "Falha na inscrição. Por favor, tente novamente.",
        fetchError: "Falha ao buscar torneios. Por favor, tente novamente mais tarde.",
    }
};

const CategoryCard = ({ category }) => (
    <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
        <h4 className="font-semibold text-lg mb-2">Categories</h4>
        <ul className="list-disc list-inside mb-2">
            {category.category.map((cat, idx) => (
                <li key={`${cat}-${idx}`}>{cat}</li>
            ))}
        </ul>
        <h4 className="font-semibold text-lg mb-2">Belts</h4>
        <ul className="list-disc list-inside mb-2">
            {category.belts.map((belt, idx) => (
                <li key={`${belt}-${idx}`}>{belt}</li>
            ))}
        </ul>
        <h4 className="font-semibold text-lg mb-2">Styles</h4>
        <p>{category.styles}</p>
    </div>
);

export const Enroll = () => {
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournamentId, setSelectedTournamentId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        fetchTournaments();
    }, []);

    const fetchTournaments = async () => {
        try {
            const fetchedTournaments = await getAvailableTournamentsForUserId(getFromLocalStorage('userId', 0));
            setTournaments(fetchedTournaments);
            if (fetchedTournaments.length > 0) {
                setSelectedTournamentId(fetchedTournaments[0].tournament_id);
            }
        } catch (err) {
            setError('Failed to fetch tournaments. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleTournamentChange = (value) => {
        setSelectedTournamentId(Number(value));
    };

    const handleEnroll = async (e) => {
        e.preventDefault();
        const selectedTournament = tournaments.find(t => t.tournament_id === selectedTournamentId);
        if (selectedTournament) {
            setLoading(true);
            try {
                const payload = { tournament_id: selectedTournament.tournament_id, user_id: getFromLocalStorage('userId', 0) };
                const response = await enrollToTournament(payload);
                if (response) {
                    toastr.success(t.enrollSuccess);
                    setTournaments(tournaments.filter(t => t.tournament_id !== selectedTournament.tournament_id));
                }
            } catch (error) {
                toastr.error(t.enrollError);
            } finally {
                setLoading(false);
            }
        }
    };

    const tournamentOptions = tournaments.map(t => ({
        value: t.tournament_id.toString(),
        label: t.tournament
    }));

    const selectedTournament = tournaments.find(t => t.tournament_id === selectedTournamentId);

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <UCard
                title={t.findTournaments}
                bodyClass="space-y-6"
            >
                {loading ? (
                    <ULoading />
                ) : error ? (
                    <div className="text-center text-red-500">{t.fetchError}</div>
                ) : tournaments.length === 0 ? (
                    <div className="text-center">{t.noTournaments}</div>
                ) : (
                    <>
                        <div className="mb-4">
                            <label htmlFor="tournament-select" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                {t.selectTournament}
                            </label>
                            <USelect
                                options={tournamentOptions}
                                value={selectedTournamentId ? selectedTournamentId.toString() : ''}
                                onChange={handleTournamentChange}
                                placeholder={t.selectTournament}
                            />
                        </div>

                        {selectedTournament && (
                            <div className="space-y-4">
                                <div className="text-lg font-semibold text-gray-900 dark:text-white">{t.tournamentDetails}</div>
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <span className="font-medium text-gray-700 dark:text-gray-300">{t.name}</span>
                                        <span className="ml-2 text-gray-900 dark:text-white">{selectedTournament.tournament}</span>
                                    </div>
                                    <div>
                                        <span className="font-medium text-gray-700 dark:text-gray-300">{t.date}</span>
                                        <span className="ml-2 text-gray-900 dark:text-white">{selectedTournament.date}</span>
                                    </div>
                                    <div>
                                        <span className="font-medium text-gray-700 dark:text-gray-300">{t.location}</span>
                                        <span className="ml-2 text-gray-900 dark:text-white">{selectedTournament.location}</span>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <div className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">{t.categories}</div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        {selectedTournament.categories.map((category, index) => (
                                            <CategoryCard key={`category-${index}`} category={category} t={t} />
                                        ))}
                                    </div>
                                </div>

                                <button
                                    onClick={handleEnroll}
                                    className="mt-4 w-full bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    {t.enroll}
                                </button>
                            </div>
                        )}
                    </>
                )}
            </UCard>
        </div>
    );
};
