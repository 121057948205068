import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UCard } from "../CustomComponents/UCard";
import { ThemeLanguageToggle } from "../CustomComponents/ThemeLanguageToggle";
import { useLanguage } from "../contexts/LanguageContext";
import { USelect } from "../CustomComponents/USelect";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import toastr from 'toastr';
import { saveToLocalStorage } from "../utils/localStorageHandler";
import { signUpUser, postUserPhoto } from "../server/user/[...].user.post";
import { getKeyData } from "../server/key_data/[...].key_data.get";
import countryFlags from "../utils/CountryFlags";
import { ULoading } from "../CustomComponents/ULoading";

const translations = {
    pt: {
        signUp: 'Registrar-se em Abada Capoeira',
        name: 'Nome',
        nickname: 'Apelido',
        category: 'Categoria',
        belt: 'Corda',
        weight: 'Peso',
        dob: 'Data de Nascimento',
        gender: 'Gênero',
        country: 'País',
        state: 'Estado',
        password: 'Senha',
        mobileNumber: 'Número de Celular',
        terms: 'Eu aceito os Termos e Condições',
        submit: 'Registrar-se',
        login: 'Já tem uma conta? Entrar',
        profilePicture: 'Foto de Perfil',
        selectCategory: 'Selecione a Categoria',
        selectBelt: 'Selecione a Corda',
        selectCountry: 'Selecione o País',
        fillIn: 'Por favor, preencha todos os campos',
        ppRequired: 'Por favor, adicione uma foto de perfil',
        ppFormat: 'A foto de perfil deve ser JPEG, JPG ou PNG',
        tycAccept: 'Por favor, aceite os termos e condições',
        passLength: 'A senha deve ter pelo menos 8 caracteres',
        ppUploaded: 'Foto de perfil enviada com sucesso',
        ppUploadError: 'Erro ao enviar a foto de perfil',
        serverError: 'Erro do servidor: ',
        cancel: 'Cancelar'
    },
    en: {
        signUp: 'Sign Up to Abada Capoeira',
        name: 'Name',
        nickname: 'Nickname',
        category: 'Category',
        belt: 'Belt',
        weight: 'Weight',
        dob: 'Date of Birth',
        gender: 'Gender',
        country: 'Country',
        state: 'State',
        password: 'Password',
        mobileNumber: 'Mobile Number',
        terms: 'I accept the Terms and Conditions',
        submit: 'Submit',
        login: 'Already have an account? Login',
        profilePicture: 'Profile Picture',
        selectCategory: 'Select Category',
        selectBelt: 'Select Belt',
        selectCountry: 'Select Country',
        fillIn: 'Please fill in all fields',
        ppRequired: 'Please add a profile picture',
        ppFormat: 'Profile picture must be JPEG, JPG or PNG',
        tycAccept: 'Please accept the terms and conditions',
        passLength: 'Password must be at least 8 characters long',
        ppUploaded: 'Profile picture uploaded successfully',
        ppUploadError: 'Error uploading profile picture',
        serverError: 'Server error: ',
        cancel: 'Cancel'
    }
};

export const SignUp = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '', nickname: '', category: '', belt: '', weight: '', dob: '',
        gender: '', country: '', state: '', password: '', phone: '',
    });
    const [photo, setPhoto] = useState(null);
    const [termsViewed, setTermsViewed] = useState(false);
    const [serverData, setServerData] = useState({ categories: [], belts: [], gender: [] });
    const [loading, setLoading] = useState(false);
    const [loadingKeyData, setLoadingKeyData] = useState(false);
    const { language } = useLanguage();
    const t = translations[language];

    const categoriesWithBelts = ['1', '2', '3', '4'];

    const countryOptions = Object.keys(countryFlags).map(key => ({
        value: key,
        label: `${countryFlags[key]} ${key}`,
    }));

    useEffect(() => {
        const fetchKeyData = async () => {
            setLoadingKeyData(true);
            try {
                const response = await getKeyData();
                setServerData(response);
            } catch (error) {
                toastr.error("Error fetching data from server");
            } finally {
                setLoadingKeyData(false);
            }
        };
        fetchKeyData();
    }, []);

    const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handlePhoneChange = (value) => setFormData({ ...formData, phone: value });

    const handleCountryChange = (value) => setFormData({ ...formData, country: value });

    const handleCategoryChange = (value) => {
        setFormData(prev => ({
            ...prev,
            category: value,
            belt: categoriesWithBelts.includes(value) ? prev.belt : ''
        }));
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setPhoto(e.target.files[0]);
        }
    };

    const handleTermsView = () => setTermsViewed(true);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation checks
        for (let key in formData) {
            if (formData[key].length === 0 && key !== 'belt') {
                toastr.error(t.fillIn);
                return;
            }
        }

        if (!photo) {
            toastr.error(t.ppRequired);
            return;
        }

        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(photo.type)) {
            toastr.error(t.ppFormat);
            return;
        }

        if (!termsViewed) {
            toastr.error(t.tycAccept);
            return;
        }

        if (formData.password.length < 8) {
            toastr.warning(t.passLength);
            return;
        }

        setLoading(true);

        try {
            const submitData = {
                ...formData,
                nickname: formData.nickname.trim(),
                name: formData.name.trim(),
                category: parseInt(formData.category, 10),
                gender: parseInt(formData.gender, 10),
                weight: parseInt(formData.weight, 10),
                belt: categoriesWithBelts.includes(formData.category) ? parseInt(formData.belt, 10) : 0
            };

            const response = await signUpUser(submitData);

            const photoFormData = new FormData();
            photoFormData.append('user_id', response.user_id);
            photoFormData.append('photo', photo);

            if (await postUserPhoto(photoFormData)) {
                toastr.success(t.ppUploaded);
            } else {
                toastr.error(t.ppUploadError);
            }

            saveToLocalStorage('userId', response.user_id);
            saveToLocalStorage('authToken', true);
            navigate('/profile');
        } catch (error) {
            toastr.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    if (loadingKeyData) {
        return (
            <div className="flex justify-center items-center h-screen">
                <ULoading size="lg" />
            </div>
        );
    }
    return (
        <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <UCard
                    title={<h2 className="text-title text-center">{t.signUp}</h2>}
                    headerClass="text-center relative pb-8"
                    bodyClass="space-y-4"
                    footerClass="text-center pt-4"
                    footer={
                        <a href="/login" className="text-sm text-primary hover:text-primary-dark">
                            {t.login}
                        </a>
                    }
                >
                    <div className="absolute top-5 right-5 space-x-2 flex items-center">
                        <ThemeLanguageToggle size="sm" />
                    </div>
                    {loading && <ULoading />}
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <input type="text" name="name" placeholder={t.name} value={formData.name}
                               onChange={handleChange} className="input"/>
                        <input type="text" name="nickname" placeholder={t.nickname} value={formData.nickname}
                               onChange={handleChange} className="input"/>
                        <div className="grid grid-cols-2 gap-4">
                            <USelect
                                options={serverData.categories.map(cat => ({
                                    value: cat.id.toString(),
                                    label: cat.category
                                }))}
                                value={formData.category}
                                onChange={(value) => handleCategoryChange(value)}
                                placeholder={t.selectCategory}
                            />
                            {categoriesWithBelts.includes(formData.category) && (
                                <USelect
                                    options={serverData.belts.filter(belt => belt.id !== 0).map(belt => ({
                                        value: belt.id.toString(),
                                        label: belt.belt
                                    }))}
                                    value={formData.belt}
                                    onChange={(value) => handleChange({target: {name: 'belt', value}})}
                                    placeholder={t.selectBelt}
                                />
                            )}
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <input type="number" name="weight" placeholder={t.weight} value={formData.weight}
                                   onChange={handleChange} className="input"/>
                            <input type="date" name="dob" placeholder={t.dob} value={formData.dob}
                                   onChange={handleChange} className="input"/>
                        </div>
                        <USelect
                            options={serverData.gender.map(g => ({value: g.id.toString(), label: g.gender}))}
                            value={formData.gender}
                            onChange={(value) => handleChange({target: {name: 'gender', value}})}
                            placeholder={t.gender}
                        />
                        <div className="grid grid-cols-2 gap-4">
                            <USelect
                                options={countryOptions}
                                value={formData.country}
                                onChange={handleCountryChange}
                                placeholder={t.selectCountry}
                            />
                            <input type="text" name="state" placeholder={t.state} value={formData.state}
                                   onChange={handleChange} className="input"/>
                        </div>
                        <input type="password" name="password" placeholder={t.password} value={formData.password}
                               onChange={handleChange} className="input"/>
                        <PhoneInput
                            country={'au'}
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            inputClass="w-full p-2 mt-1 block rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                        />
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.profilePicture}</label>
                            <input type="file" onChange={handleImageChange}
                                   className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-primary file:text-white hover:file:bg-primary-dark"/>
                        </div>
                        <div className="flex items-center">
                            <input type="checkbox" id="terms" name="terms"
                                   className="form-checkbox h-4 w-4 text-primary" onClick={handleTermsView}/>
                            <label htmlFor="terms" className="ml-2 block text-sm text-body">
                                <a
                                    href="/terms-and-conditions"
                                    className="text-blue-500"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t.terms}
                                </a>
                            </label>
                        </div>
                        <button type="submit" className="btn btn-primary w-full mt-6">
                            {t.submit}
                        </button>
                        <button type="button" onClick={() => navigate('/login')} className="btn btn-secondary w-full">
                            {t.cancel}
                        </button>
                    </form>
                </UCard>
            </div>
        </div>
    );
};
