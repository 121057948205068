import React, { useEffect, useState, useCallback } from 'react';
import { USelect } from "../CustomComponents/USelect";
import { UCard } from "../CustomComponents/UCard";
import { getTournamentsForAdmin } from "../server/tournament/[...].tournament.get";
import { getMatchScores, getJudgeScores, getUpcomingMatches } from "../server/judge/[...].score.get";
import { ULoading } from "../CustomComponents/ULoading";
import { useLanguage } from "../contexts/LanguageContext";
import { UButton } from "../CustomComponents/UButton";
import toastr from "toastr";
import { swapMatchParticipants } from "../server/match/[...].match.get";
const translations = {
    en: {
        tournamentMatches: "Tournament Matches",
        selectView: "Select view",
        selectTournament: "Select Tournament",
        selectCategory: "Select Category",
        noTournamentsFound: "No tournaments found.",
        noCategoriesFound: "No categories found.",
        filterByStyle: "Filter by style",
        filterByRound: "Filter by round",
        filterByJudge: "Filter by judge",
        filterByPlayer: "Filter by player",
        round: "Round:",
        group: "Group:",
        style: "Style:",
        judge: "Judge:",
        matchScore: "Match Score:",
        highlightedMatch: "Highlighted Match",
        player: "Player",
        qualifier: "Qualifier",
        notApplicable: "N/A",
        loadError: "Failed to load data. Please try again later.",
        swapParticipants: "Swap Participants",
        match1: "Match 1",
        match2: "Match 2",
        swap: "Swap",
        swapSuccess: "Participants swapped successfully",
        swapFail: "Failed to swap participants",
        noMatchesToSwap: "No matches available to swap participants",
    },
    pt: {
        tournamentMatches: "Partidas do Torneio",
        selectView: "Selecionar visualização",
        selectTournament: "Selecionar Torneio",
        selectCategory: "Selecionar Categoria",
        noTournamentsFound: "Nenhum torneio encontrado.",
        noCategoriesFound: "Nenhuma categoria encontrada.",
        filterByStyle: "Filtrar por estilo",
        filterByRound: "Filtrar por rodada",
        filterByJudge: "Filtrar por juiz",
        filterByPlayer: "Filtrar por jogador",
        round: "Rodada:",
        group: "Grupo:",
        style: "Estilo:",
        judge: "Juiz:",
        matchScore: "Pontuação da Partida:",
        highlightedMatch: "Partida Destacada",
        player: "Jogador",
        qualifier: "Qualificatória",
        notApplicable: "N/A",
        loadError: "Falha ao carregar dados. Por favor, tente novamente mais tarde.",
        swapParticipants: "Trocar Participantes",
        match1: "Partida 1",
        match2: "Partida 2",
        swap: "Trocar",
        swapSuccess: "Participantes trocados com sucesso",
        swapFail: "Falha ao trocar participantes",
        noMatchesToSwap: "Não há partidas disponíveis para trocar participantes",
    }
};

const SwapParticipantsForm = ({ matches, setLoading, t }) => {
    const [selectedStyle, setSelectedStyle] = useState(null);
    const [selectedMatch1, setSelectedMatch1] = useState(null);
    const [selectedMatch2, setSelectedMatch2] = useState(null);
    const [selectedPlayer1, setSelectedPlayer1] = useState(null);
    const [selectedPlayer2, setSelectedPlayer2] = useState(null);

    const styleOptions = [...new Set(matches.map(m => m.style))].map(style => ({
        value: style,
        label: style
    }));

    const filteredMatches = matches.filter(m => m.style === selectedStyle);

    const handleSwap = async () => {
        if (!selectedMatch1 || !selectedMatch2 || !selectedPlayer1 || !selectedPlayer2) {
            toastr.error(t.swapFail);
            return;
        }

        try {
            setLoading(true);
            const allPlayers = [
                { id: selectedMatch1.player1, nickname: selectedMatch1.player1_nickname },
                { id: selectedMatch1.player2, nickname: selectedMatch1.player2_nickname },
                { id: selectedMatch2.player1, nickname: selectedMatch2.player1_nickname },
                { id: selectedMatch2.player2, nickname: selectedMatch2.player2_nickname }
            ];

            const selectedPlayerIds = [selectedPlayer1.value, selectedPlayer2.value];
            const unselectedPlayers = allPlayers.filter(p => !selectedPlayerIds.includes(p.id));

            const response = await swapMatchParticipants({
                match_1: selectedMatch1.match_id,
                match_2: selectedMatch2.match_id,
                match_1_player1: selectedPlayer1.value,
                match_1_player2: selectedPlayer2.value,
                match_2_player1: unselectedPlayers[0].id,
                match_2_player2: unselectedPlayers[1].id,
            });

            if (response) {
                setTimeout(() => window.location.reload(), 1000);
            }
            toastr.success(t.swapSuccess);
        } catch (error) {
            console.error("Failed to swap participants:", error);
            toastr.error(t.swapFail);
        } finally {
            setLoading(false);
        }
    };

    const getPlayerOptions = (match) => {
        if (!match) return [];
        return [
            { value: match.player1, label: match.player1_nickname },
            { value: match.player2, label: match.player2_nickname }
        ];
    };

    const allPlayerOptions = selectedMatch1 && selectedMatch2
        ? [...getPlayerOptions(selectedMatch1), ...getPlayerOptions(selectedMatch2)]
        : [];

    return (
        <UCard className="mb-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
            <div className="p-4">
                <h4 className="text-lg font-semibold mb-4">{t.swapParticipants}</h4>
                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{t.selectStyle}</label>
                        <USelect
                            options={styleOptions}
                            value={selectedStyle}
                            onChange={(value) => {
                                setSelectedStyle(value);
                                setSelectedMatch1(null);
                                setSelectedMatch2(null);
                                setSelectedPlayer1(null);
                                setSelectedPlayer2(null);
                            }}
                        />
                    </div>
                    <div className="flex space-x-4">
                        <div className="flex-1">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{t.match1}</label>
                            <USelect
                                options={filteredMatches.map(m => ({
                                    value: m.match_id,
                                    label: `${m.player1_nickname} vs ${m.player2_nickname}`
                                }))}
                                value={selectedMatch1?.match_id}
                                onChange={(value) => {
                                    const match = filteredMatches.find(m => m.match_id === value);
                                    setSelectedMatch1(match);
                                    setSelectedPlayer1(null);
                                    setSelectedPlayer2(null);
                                }}
                                disabled={!selectedStyle}
                            />
                        </div>
                        <div className="flex-1">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{t.match2}</label>
                            <USelect
                                options={filteredMatches
                                    .filter(m => m.match_id !== selectedMatch1?.match_id)
                                    .map(m => ({
                                        value: m.match_id,
                                        label: `${m.player1_nickname} vs ${m.player2_nickname}`
                                    }))}
                                value={selectedMatch2?.match_id}
                                onChange={(value) => {
                                    const match = filteredMatches.find(m => m.match_id === value);
                                    setSelectedMatch2(match);
                                    setSelectedPlayer1(null);
                                    setSelectedPlayer2(null);
                                }}
                                disabled={!selectedMatch1}
                            />
                        </div>
                    </div>
                    <div className="flex space-x-4">
                        <div className="flex-1">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{t.playerForMatch1}</label>
                            <USelect
                                options={allPlayerOptions}
                                value={selectedPlayer1?.value}
                                onChange={(value) => setSelectedPlayer1(allPlayerOptions.find(p => p.value === value))}
                                disabled={!selectedMatch1 || !selectedMatch2}
                            />
                        </div>
                        <div className="flex-1">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{t.playerForMatch1}</label>
                            <USelect
                                options={allPlayerOptions.filter(p => p.value !== selectedPlayer1?.value)}
                                value={selectedPlayer2?.value}
                                onChange={(value) => setSelectedPlayer2(allPlayerOptions.find(p => p.value === value))}
                                disabled={!selectedMatch1 || !selectedMatch2 || !selectedPlayer1}
                            />
                        </div>
                    </div>
                </div>
                <UButton
                    className="w-full mt-4 px-4 py-2 bg-primary hover:bg-primary-dark"
                    onClick={handleSwap}
                    disabled={!selectedMatch1 || !selectedMatch2 || !selectedPlayer1 || !selectedPlayer2}
                >
                    {t.swap}
                </UButton>
            </div>
        </UCard>
    );
};

const styles_color_mapping = [
    { style_id: 1, color: 'bg-blue-200 dark:bg-blue-700' },
    { style_id: 2, color: 'bg-green-200 dark:bg-green-700' },
    { style_id: 3, color: 'bg-purple-200 dark:bg-purple-700' },
]

const defaultOptions = ['Technica', 'Objectividade', 'Continuidade', 'Criatividade', 'Caracterizacao'];

const parseDiscounts = (discountString, isJudge) => {
    if (!discountString) return {};
    try {
        return Object.fromEntries(
            discountString.split(') (').map(item => {
                const [key, value] = item.replace(/[()]/g, '').split(',');
                if (isJudge) return [key, parseFloat(value) / 10];
                return [key, parseFloat(value)];
            })
        );
    } catch (error) {
        console.error('Error parsing discounts:', error);
        return {};
    }
};

const ReadOnlyJudgeForm = ({ match, t }) => {
    if (!match) return null;

    const isJudgeScore = !!match.judge;
    const discountsPlayer1 = parseDiscounts(match.discount_player1, isJudgeScore);
    const discountsPlayer2 = parseDiscounts(match.discount_player2, isJudgeScore);

    const handleStyleColor = () => {
        const foundStyle = styles_color_mapping.find((style) => style.style_id === match.style_id);
        return foundStyle ? foundStyle.color : '';
    }

    return (
        <UCard className="mb-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
            <div className={`${handleStyleColor()} p-4 rounded-md`}>
                <div className="flex flex-col items-center space-y-2 text-sm">
                    <span className='text-xl'><strong>{t.round}</strong> {match.round || t.qualifier}</span>
                    {match.group > 0 && (
                        <span className='text-xl'><strong>{t.group}</strong> {match.group}</span>
                    )}
                    <span className='text-xl'><strong>{t.style}</strong> {match.style || t.notApplicable}</span>
                    {match.judge && (
                        <span className='text-xl'><strong>{t.judge}</strong> {match.judgeName}</span>
                    )}
                </div>
            </div>
            <div className="p-4">
                <div className="sm:flex sm:justify-between sm:items-center mb-4">
                    {[
                        { name: match.player1_nickname, total: match.total_player1, discounts: discountsPlayer1 },
                        { name: match.player2_nickname, total: match.total_player2, discounts: discountsPlayer2 }
                    ].map((player, index) => (
                        <div key={index} className="mb-4 sm:mb-0 w-full sm:w-[48%]">
                            <div className="text-lg text-center font-semibold bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center">
                                <span>{player.name || `${t.player} ${index + 1}`}</span>
                                {player.total && (
                                    <span className="ml-2 bg-white text-blue-500 px-2 py-1 rounded-full text-sm">
                                        {player.total.toFixed(1)}
                                    </span>
                                )}
                            </div>
                            {player.total && (
                                <div className="mt-2 space-y-2">
                                    {defaultOptions.map(option => (
                                        <div key={`Player${index + 1}-${option}`} className="space-y-1">
                                            <label className="flex justify-between">
                                                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">{option}</span>
                                                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                                    {player.discounts[option] !== undefined ? player.discounts[option].toFixed(1) : t.notApplicable}
                                                </span>
                                            </label>
                                            <div className="w-full h-2 bg-gray-200 rounded-lg dark:bg-gray-700">
                                                <div
                                                    className="h-full bg-blue-500 rounded-lg"
                                                    style={{width: `${Math.min((player.discounts[option] || 0) * 100, 100)}%`}}
                                                ></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {match.score && (
                    <div className="flex flex-col items-center justify-center my-4">
                        <label className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">{t.matchScore}</label>
                        <span className="text-xl font-bold">{match.score}</span>
                    </div>
                )}
                {match.highlighted_match === 1 && (
                    <div className="flex items-center justify-center mt-4">
                        <span className="px-2 py-1 bg-yellow-200 text-yellow-800 rounded-full text-sm">{t.highlightedMatch}</span>
                    </div>
                )}
            </div>
        </UCard>
    );
};

const Navigation = ({
                    viewOptions,
                    selectedView,
                    handleViewChange,
                    selectedTournament,
                    tournaments,
                    selectedCategory,
                    onTournamentChange,
                    onCategoryChange,
                    t
                    }) => {
    return (
        <UCard>
            <div className="justify-center grid grid-cols-1 md:grid-cols-3 gap-2">
                <div>
                    <h2 className="text-center font-semibold text-md mb-2">{t.selectView}</h2>
                    <USelect
                        options={viewOptions}
                        value={selectedView.value}
                        onChange={handleViewChange}
                    />
                </div>

                <div>
                    <h2 className="text-center font-semibold text-md mb-2">{t.selectTournament}</h2>
                    {tournaments && tournaments.length > 0 ? (
                        <USelect
                            options={tournaments.map((tournament) => ({
                                value: tournament.tournament_id,
                                label: tournament.tournament_name
                            }))}
                            value={selectedTournament?.tournament_id}
                            onChange={onTournamentChange}
                            className="w-full"
                        />
                    ) : (
                        <div className="text-md text-gray-700">
                            {t.noTournamentsFound}
                        </div>
                    )}
                </div>

                <div>
                    <h2 className="text-center font-semibold text-md mb-2">{t.selectCategory}</h2>
                    {selectedTournament && selectedTournament.categories.length > 0 ? (
                        <USelect
                            options={selectedTournament.categories.map((category) => ({
                                value: category.category_id,
                                label: category.category_name
                            }))}
                            value={selectedCategory?.category_id}
                            onChange={onCategoryChange}
                            className="w-full"
                        />
                    ) : (
                        <div className="text-md text-gray-700">
                            {t.noCategoriesFound}
                        </div>
                    )}
                </div>
            </div>
        </UCard>
    );
};

const Filters = ({
                     styles,
                     onStyleChange,
                     rounds,
                     onRoundChange,
                     judges,
                     onJudgeChange,
                     players,
                     onPlayerChange,
                     t
                 }) => {
    return (
        <UCard>
            <div className="justify-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                {styles.length > 0 && (
                    <div>
                        <h2 className="text-center font-semibold text-md mb-2">{t.filterByStyle}</h2>
                        <USelect
                            options={styles}
                            onChange={onStyleChange}
                            className="w-full"
                        />
                    </div>
                )}
                {rounds.length > 0 && (
                    <div>
                        <h2 className="text-center font-semibold text-md mb-2">{t.filterByRound}</h2>
                        <USelect
                            options={rounds}
                            onChange={onRoundChange}
                            className="w-full"
                        />
                    </div>
                )}
                {judges.length > 1 && (
                    <div>
                        <h2 className="text-center font-semibold text-md mb-2">{t.filterByJudge}</h2>
                        <USelect
                            options={judges}
                            onChange={onJudgeChange}
                            className="w-full"
                        />
                    </div>
                )}
                {players.length > 0 && (
                    <div>
                        <h2 className="text-center font-semibold text-md mb-2">{t.filterByPlayer}</h2>
                        <USelect
                            options={players}
                            onChange={onPlayerChange}
                            className="w-full"
                        />
                    </div>
                )}
            </div>
        </UCard>
    );
};

const viewOptions = [
    { label: "Upcoming matches", value: 1 },
    { label: "Judge scores", value: 2 },
    { label: "Match scores", value: 3 }
];

export const TournamentMatches = () => {
    const [loading, setIsLoading] = useState(false);
    const [selectedView, setSelectedView] = useState(viewOptions[0]);
    const [tournaments, setTournaments] = useState(null);
    const [selectedTournament, setSelectedTournament] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [matches, setMatches] = useState([]);
    const [selectedStyle, setSelectedStyle] = useState(null);
    const [selectedRound, setSelectedRound] = useState(null);
    const [selectedJudge, setSelectedJudge] = useState(null);
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        fetchTournaments();
    }, []);

    const fetchTournaments = async () => {
        setIsLoading(true);
        try {
            const response = await getTournamentsForAdmin();
            setTournaments(response);
            setSelectedTournament(response[0]);
            setSelectedCategory(response[0].categories[0]);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchMatches = useCallback(async () => {
        setIsLoading(true);
        try {
            if (selectedTournament && selectedCategory) {
                let response;
                if (selectedView.value === 1) {
                    response = await getUpcomingMatches(selectedCategory.category_id);
                } else if (selectedView.value === 2) {
                    response = await getJudgeScores(selectedCategory.category_id);
                } else {
                    response = await getMatchScores(selectedCategory.category_id);
                }
                setMatches(response);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [selectedView, selectedTournament, selectedCategory]);

    useEffect(() => {
        fetchMatches();
    }, [fetchMatches, selectedView, selectedTournament, selectedCategory]);

    const handleTournamentChange = (tournamentId) => {
        const newTournament = tournaments.find(tournament => tournament.tournament_id === tournamentId);
        setSelectedTournament(newTournament);
        setSelectedCategory(newTournament.categories[0]);
    };

    useEffect(() => {
        // Update category when tournament changes or if category is not set
        if (selectedTournament && (!selectedCategory || !selectedTournament.categories.includes(selectedCategory))) {
            setSelectedCategory(selectedTournament.categories[0]);
        }
    }, [selectedTournament, selectedCategory]);

    const handleCategoryChange = (tournamentCategoryId) => {
        const selectedCat = selectedTournament.categories.find(category => category.category_id === tournamentCategoryId);
        setSelectedCategory(selectedCat);
    };

    const handleViewChange = (viewId) => {
        const newView = viewOptions.find((view) => view.value === viewId);
        setSelectedView(newView);
    };

    const handleStyleChange = (style) => {
        setSelectedStyle(style === 0 ? null : style);
    };

    const handleRoundChange = (round) => {
        setSelectedRound(round === 0 ? null : round);
    };

    const handleJudgeChange = (judge) => {
        setSelectedJudge(judge === 0 ? null : judge);
    };

    const handlePlayerChange = (player) => {
        setSelectedPlayer(player === 0 ? null : player);
    };

    const filteredMatches = matches.filter(match =>
        (!selectedStyle || match.style_id === selectedStyle) &&
        (!selectedRound || (selectedRound === 'qualifier' ? match.round === 0 : match.round === selectedRound)) &&
        (!selectedJudge || match.judge === selectedJudge) &&
        (!selectedPlayer || match.player1 === selectedPlayer || match.player2 === selectedPlayer)
    );

    const styleMap = new Map();
    const roundMap = new Map();
    const judgeMap = new Map();
    const playerMap = new Map();

    matches.forEach(match => {
        styleMap.set(match.style_id, { value: match.style_id, label: match.style });
        if (match.round === 0) {
            roundMap.set('qualifier', { value: 'qualifier', label: 'Qualifier' });
        } else {
            roundMap.set(match.round, { value: match.round, label: `Round ${match.round}` });
        }
        if (match.judge) judgeMap.set(match.judge, { value: match.judge, label: match.judgeName });
        playerMap.set(match.player1, { value: match.player1, label: match.player1_nickname });
        playerMap.set(match.player2, { value: match.player2, label: match.player2_nickname });
    });

    const styles = [{ value: 0, label: 'All Styles' }, ...Array.from(styleMap.values())];
    const rounds = [{ value: 0, label: 'All Rounds' }, ...Array.from(roundMap.values())];
    const judges = [{ value: 0, label: 'All Judges' }, ...Array.from(judgeMap.values())];
    const players = [{ value: 0, label: 'All Players' }, ...Array.from(playerMap.values())];

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <Navigation
                viewOptions={viewOptions}
                selectedView={selectedView}
                handleViewChange={handleViewChange}
                selectedTournament={selectedTournament}
                tournaments={tournaments}
                selectedCategory={selectedCategory}
                onTournamentChange={handleTournamentChange}
                onCategoryChange={handleCategoryChange}
                t={t}
            />
            {matches.length > 0 && (
                <div className="mt-4">
                    <Filters
                        styles={styles}
                        onStyleChange={handleStyleChange}
                        rounds={rounds}
                        onRoundChange={handleRoundChange}
                        judges={judges}
                        onJudgeChange={handleJudgeChange}
                        players={players}
                        onPlayerChange={handlePlayerChange}
                        t={t}
                    />
                </div>
            )}
            <div className="mt-4">
                <UCard
                    title={selectedView.label}
                    bodyClass="overflow-y-auto"
                >
                    {loading ? (
                        <ULoading />
                    ) : (
                        <>
                            {selectedView.value === 1 && filteredMatches.some(match => match.round === 0) && (
                                <SwapParticipantsForm
                                    matches={filteredMatches.filter(match => match.round === 0)}
                                    setLoading={setIsLoading}
                                    t={t}
                                />
                            )}
                            {filteredMatches.length > 0 ? (
                                filteredMatches.map((match, idx) => (
                                    <div className="mt-4" key={idx}>
                                        <ReadOnlyJudgeForm match={match} t={t} />
                                    </div>
                                ))
                            ) : (
                                <div className="text-center text-xl font-bold">No matches found.</div>
                            )}
                        </>
                    )}
                </UCard>
            </div>
        </div>
    );
};
