import React, { useState, useEffect } from 'react';
import { UCard } from '../CustomComponents/UCard';
import { UTable } from '../CustomComponents/UTable';
import { ThemeLanguageToggle } from '../CustomComponents/ThemeLanguageToggle';
import { getUser, getUsers } from "../server/user/[...].user.get";
import { deleteUser } from "../server/user/[...].user.delete";
import { reactivateUser } from "../server/user/[...].user.patch";
import { saveToLocalStorage, getFromLocalStorage } from "../utils/localStorageHandler";
import { useLanguage } from "../contexts/LanguageContext";
import { EditProfile } from "./EditProfile";
import { UInput } from "../CustomComponents/UInput";
import { ULoading } from "../CustomComponents/ULoading";
import { UButton } from "../CustomComponents/UButton";

const translations = {
    en: {
        findProfile: "Find Profiles",
        viewProfile: "View Profile",
        search: "Search profiles...",
        userProfile: "User Profile",
        editProfile: "Edit Profile",
        deactivateUser: "Deactivate User",
        reactivateUser: "Reactivate User",
        closeProfile: "Close Profile",
        active: "Active",
        inactive: "Inactive",
        loading: "Loading...",
        serverError: "Failed to connect to the server. Please try again later.",
        nickname: 'Nickname'
    },
    pt: {
        findProfile: "Encontrar Perfil",
        viewProfile: "Ver Perfil",
        search: "Pesquisar perfis...",
        userProfile: "Perfil do Usuário",
        editProfile: "Editar Perfil",
        deactivateUser: "Desativar Usuário",
        reactivateUser: "Reativar Usuário",
        closeProfile: "Fechar Perfil",
        active: "Ativo",
        inactive: "Inativo",
        loading: "Carregando...",
        serverError: "Falha ao conectar ao servidor. Por favor, tente novamente mais tarde.",
    }
};

const UserProfilePopUp = ({ user, closeModal, deactivateUser, reactivateUser, setIsEditing, loading, t }) => {
    if (!user) {
        return <div>{t.loading}</div>;
    }

    const filteredUser = Object.entries(user).filter(([key]) => !key.includes('id') && key !== 'imageUrl');

    const handleDeactivateUser = (e) => {
        e.preventDefault();
        deactivateUser(user.id);
    }

    const handleReactivateUser = (e) => {
        e.preventDefault();
        reactivateUser(user.id);
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <UCard
                title={<h2 className="text-2xl font-bold text-text-DEFAULT dark:text-text-dark">{t.userProfile}</h2>}
                bodyClass="space-y-4"
                className={`w-full max-w-lg ${user.active === 1 ? 'border-green-500' : 'border-red-500'} border-4`}
            >
                {loading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-DEFAULT"></div>
                    </div>
                )}
                {user.imageUrl && (
                    <div className="flex justify-center">
                        <img src={user.imageUrl} alt={`${user.name}'s profile`} className="w-32 h-32 rounded-full" />
                    </div>
                )}
                <div className="grid grid-cols-2 gap-4">
                    {filteredUser.map(([key, value]) => (
                        <div key={key}>
                            <p className="font-medium text-text-light dark:text-text-dark">{key.charAt(0).toUpperCase() + key.slice(1)}:</p>
                            {key === 'active' ? (
                                <span className={`px-2 py-1 rounded ${value === 1 ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                                    {value === 1 ? t.active : t.inactive}
                                </span>
                            ) : (
                                <p className="text-text-DEFAULT dark:text-text-dark">{value}</p>
                            )}
                        </div>
                    ))}
                </div>
                <div className="flex justify-end space-x-2 mt-4">
                    <UButton
                        className="px-4 py-2 bg-primary-light hover:bg-primary-DEFAULT text-white rounded-md transition-colors duration-150 ease-in-out"
                        onClick={() => setIsEditing(true)}
                    >
                        {t.editProfile}
                    </UButton>
                    {user.active === 1 ? (
                        <UButton
                            className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md transition-colors duration-150 ease-in-out"
                            onClick={handleDeactivateUser}
                        >
                            {t.deactivateUser}
                        </UButton>
                    ) : (
                        <UButton
                            className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-md transition-colors duration-150 ease-in-out"
                            onClick={handleReactivateUser}
                        >
                            {t.reactivateUser}
                        </UButton>
                    )}
                    <UButton
                        className="px-4 py-2 bg-primary hover:bg-gray-400 text-gray-800 rounded-md transition-colors duration-150 ease-in-out"
                        onClick={closeModal}
                    >
                        {t.closeProfile}
                    </UButton>
                </div>
            </UCard>
        </div>
    );
};

export const FindProfile = () => {
    const [people, setPeople] = useState(getFromLocalStorage('nicknames', []));
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const response = await getUsers();
            if (response) {
                setPeople(response);
                saveToLocalStorage('nicknames', response);
            }
        } catch (error) {
            console.error('Failed to fetch users:', error);
            setServerError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleRowClick = async (user) => {
        setIsLoading(true);
        try {
            const fullUserData = await getUser(user.id);
            setSelectedUser(fullUserData);
            setShowPopup(true);
        } catch (error) {
            console.error('Failed to fetch user details:', error);
            setServerError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeactivateUser = async (userId) => {
        setIsLoading(true);
        try {
            const response = await deleteUser(userId);
            if (response) {
                setSelectedUser(null);
                setShowPopup(false);
                fetchUsers(); // Refresh the user list
            }
        } catch (error) {
            console.error('Failed to deactivate user:', error);
            setServerError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleReactivateUser = async (userId) => {
        setIsLoading(true);
        try {
            const response = await reactivateUser(userId);
            if (response) {
                setSelectedUser(null);
                setShowPopup(false);
                fetchUsers(); // Refresh the user list
            }
        } catch (error) {
            console.error('Failed to reactivate user:', error);
            setServerError(true);
        } finally {
            setIsLoading(false);
        }
    };

    /*
    const updateUserProfile = (updatedUser) => {
        setSelectedUser(updatedUser);
        fetchUsers(); // Refresh the user list
    };
*/
    const closeModal = () => {
        setSelectedUser(null);
        setShowPopup(false);
        setIsEditing(false);
    };

    const filteredPeople = people.filter(person =>
        person.nickname.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const tableHeaders = ['', 'Nickname', '', ''];
    const tableData = filteredPeople.map(person => ({
        id: person.id,
        nickname: person.nickname,
        name: person.name,
        category: person.category
    }));

    if (serverError) {
        return (
            <div className="container mx-auto px-4 py-8">
                <UCard>
                    <div className="text-red-500 text-center">{t.serverError}</div>
                </UCard>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <UCard
                title={<h2 className="text-2xl font-bold text-text-DEFAULT dark:text-text-dark">{t.findProfile}</h2>}
                headerClass="flex justify-between items-center"
                headerExtra={<ThemeLanguageToggle />}
                bodyClass="space-y-6"
            >
                <UInput
                    type="text"
                    className="w-full p-2 border border-secondary-light dark:border-darkMode-lighter rounded-md bg-background-light dark:bg-darkMode-DEFAULT text-text-DEFAULT dark:text-text-dark"
                    placeholder={t.search}
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                {isLoading ? (
                    <ULoading />
                ) : (
                    <UTable
                        headers={tableHeaders}
                        data={tableData}
                        onRowClick={handleRowClick}
                    />
                )}
            </UCard>

            {showPopup && !isEditing && (
                <UserProfilePopUp
                    user={selectedUser}
                    closeModal={closeModal}
                    deactivateUser={handleDeactivateUser}
                    reactivateUser={handleReactivateUser}
                    setIsEditing={setIsEditing}
                    loading={isLoading}
                    t={t}
                />
            )}

            {isEditing && (
                <EditProfile
                    user={selectedUser}
                    onClose={closeModal}
                />
            )}
        </div>
    );
};
