import React, { useState, useEffect, useCallback } from 'react';
import { UCard } from '../CustomComponents/UCard';
import { ULoading } from "../CustomComponents/ULoading";
import { UButton } from "../CustomComponents/UButton";
import {
    getTournamentsByUserId,
    getTournamentDrawById,
    getTournamentDetails
} from "../server/tournament/[...].tournament.get";
import { getFromLocalStorage, saveToLocalStorage } from '../utils/localStorageHandler';
import { useLanguage } from "../contexts/LanguageContext";
import toastr from "toastr";
import { USelect } from "../CustomComponents/USelect";
import countryFlags from "../utils/CountryFlags";
import { categoryColors } from "../utils/categoryColors";
import { beltColors } from "../utils/beltColors";

const translations = {
    en: {
        noGroups: "No group has been assigned yet.",
        tDetails: "Tournament details",
        winners: "Winners",
        final: "Final",
        semi: "Semifinal",
        quarter: "Quarter Final",
        error: "Error: Unable to fetch data from the server.",
        qualys: "Qualifiers",
        selectTandC: "Select tournament and category",
        selectT: "Select tournament",
        noTournaments: "You are not participating in any tournament just yet",
        tournamentDetails: "Tournament Details",
        name: "Name:",
        date: "Date:",
        location: "Location:",
        categories: "Categories",
        belts: "Belts",
        styles: "Styles",
        close: "Close",
        loading: "Loading...",
        injured: "Injured",
        disqualified: "Disqualified",
        noShow: "No show",
    },
    pt: {
        noGroups: "Nenhum grupo foi atribuído ainda.",
        tDetails: "Detalhes do torneio",
        winners: "Vencedores",
        final: "Final",
        semi: "Semifinal",
        quarter: "Quartas de final",
        error: "Erro: Não é possível buscar dados do servidor.",
        qualys: "Eliminatórias",
        selectTandC: "Selecionar torneio e categoria",
        selectT: "Selecionar torneio",
        noTournaments: "Você ainda não está participando de nenhum torneio",
        tournamentDetails: "Detalhes do Torneio",
        name: "Nome:",
        date: "Data:",
        location: "Local:",
        categories: "Categorias",
        belts: "Cordas",
        styles: "Estilos",
        close: "Fechar",
        loading: "Carregando...",
        injured: "Injured",
        disqualified: "Disqualified",
        noShow: "No show",
    }
};

function capitalizeFirstLetter(string) {
    return string.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const CategoryCard = ({ category, t }) => (
    <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
        <h4 className="font-semibold text-lg mb-2">{t.categories}</h4>
        <ul className="list-disc list-inside mb-2">
            {category.category.map((cat, idx) => (
                <li key={`${cat}-${idx}`}>{cat}</li>
            ))}
        </ul>
        <h4 className="font-semibold text-lg mb-2">{t.belts}</h4>
        <ul className="list-disc list-inside mb-2">
            {category.belts.map((belt, idx) => (
                <li key={`${belt}-${idx}`}>{belt}</li>
            ))}
        </ul>
        <h4 className="font-semibold text-lg mb-2">{t.styles}</h4>
        <p>{category.styles}</p>
    </div>
);

const Player = ({ playerName, country, score, category, belt, highlighted, status, t }) => {
    const getFlagEmoji = (countryName) => {
        return countryFlags[capitalizeFirstLetter(countryName)] || '';
    };

    const getCategoryColor = (categoryId) => {
        return categoryColors[categoryId] || '';
    }

    const getBeltColor = (beltId) => {
        return beltColors[beltId] || '';
    }

    const getStatusStyle = () => {
        switch (status) {
            case 'injured':
                return 'bg-yellow-200 dark:bg-yellow-800';
            case 'disqualified':
                return 'bg-red-400 dark:bg-red-800';
            case 'no show':
                return 'bg-red-400 dark:bg-red-800';
            default:
                return '';
        }
    }

    const renderStatusIndicator = () => {
        switch (status) {
            case 'injured':
                return (
                    <div className="h-6 flex items-center justify-center flex-grow font-bold text-yellow-800 dark:text-yellow-200">
                        🤕 { t.injured }
                    </div>
                );
            case 'disqualified':
                return (
                    <div className="h-6 flex items-center justify-center flex-grow font-bold text-red-800 dark:text-red-200">
                        ❌ { t.disqualified }
                    </div>
                );
            case 'no show':
                return (
                    <div className="h-6 flex items-center justify-center flex-grow font-bold text-red-800 dark:text-red-200">
                        👎 { t.noShow }
                    </div>
                );
            default:
                return null;
        }
    }

    return (
        <div className={`bg-white dark:bg-background-dark text-black dark:text-white w-full h-16 rounded-xl p-4 flex items-center justify-between overflow-hidden shadow-md ${getStatusStyle()}`}>
            <div className="flex items-center space-x-4 overflow-hidden">
                <span className="text-black dark:text-white font-semibold text-lg truncate">{playerName}</span>
            </div>
            <div className="flex items-center space-x-3 gap-2">
                <div className="w-8 h-10 flex items-center justify-center ml-6">
                    <span className="text-2xl">{getFlagEmoji(country)}</span>
                </div>
                <div className="h-10 flex items-center justify-center flex-grow">
                    {getCategoryColor(category)}
                </div>
                {belt && (
                    <div className="h-6 flex items-center justify-center flex-grow">
                        {getBeltColor(belt)}
                    </div>
                )}
                {highlighted && <div className="text-yellow-500 text-xl">⭐️</div>}
                {score && (
                    <div className="bg-darkMode-lighter text-white rounded-lg w-12 h-12 flex items-center justify-center font-bold text-xl">
                        {score}
                    </div>
                )}
                {renderStatusIndicator()}
            </div>
        </div>
    );
}

const TournamentDetails = ({tournament, closeModal}) => {
    const [tournamentDetails, setTournamentDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { language } = useLanguage();
    const t = translations[language];

    useEffect(() => {
        const fetchTournamentData = async () => {
            if (!tournament) return;
            try {
                const fetchedTournamentData = await getTournamentDetails(tournament);

                if (fetchedTournamentData) {
                    setTournamentDetails(fetchedTournamentData);
                }
            } catch (error) {
                toastr.error(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTournamentData();
    }, [tournament]);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <UCard
                title={t.tournamentDetails}
                bodyClass="space-y-4 max-h-[80vh] overflow-y-auto"
                className="w-full max-w-2xl"
                footer={<UButton disabled={isLoading} onClick={closeModal} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none'> {t.close}</UButton>}
            >
                {isLoading ? <ULoading /> : (
                    <div className="space-y-4">
                        <div className="text-lg font-semibold text-gray-900 dark:text-white">{t.tournamentDetails}</div>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <span className="font-medium text-gray-700 dark:text-gray-300">{t.name}</span>
                                <span className="ml-2 text-gray-900 dark:text-white">{tournamentDetails.tournament}</span>
                            </div>
                            <div>
                                <span className="font-medium text-gray-700 dark:text-gray-300">{t.date}</span>
                                <span className="ml-2 text-gray-900 dark:text-white">{tournamentDetails.date}</span>
                            </div>
                            <div>
                                <span className="font-medium text-gray-700 dark:text-gray-300">{t.location}</span>
                                <span className="ml-2 text-gray-900 dark:text-white">{tournamentDetails.location}</span>
                            </div>
                        </div>

                        <div className="mt-6">
                            <div className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">{t.categories}</div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {tournamentDetails.categories.map((category, index) => (
                                    <CategoryCard key={`category-${index}`} category={category} t={t}/>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </UCard>
        </div>
    );
}

const TournamentBracket = ({ tournamentData }) => {
    const { language } = useLanguage();
    const t = translations[language];

    const determineRoundName = useCallback((round, roundIndex) => {
        if (round.round === "Qualifier") return t.qualys;

        const totalPlayers = round.matches.reduce((sum, match) => sum + match.players.length, 0);

        switch (totalPlayers) {
            case 4: return t.final;
            case 8: return t.semi;
            case 16: return t.quarter;
            default: return `Round ${roundIndex + 1}`;
        }
    }, [t]);

    const isFinalRound = (groupIndex) => {
        return groupIndex === tournamentData.groups.length - 1;
    };

    const shouldHideScores = (groupIndex) => {
        return tournamentData.festival === 1 && isFinalRound(groupIndex);
    };

    return (
        <div className="flex h-full w-full p-4 space-x-10 overflow-auto">
            {tournamentData.groups.map((group, groupIndex) => (
                <div key={groupIndex} className="flex-none">
                    <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
                        {determineRoundName(group, groupIndex)}
                    </h3>
                    <div className="space-y-4">
                        {group.matches.map((match, matchIndex) => (
                            <div key={matchIndex} className="bg-gray-200 dark:bg-darkMode-lighter p-4 rounded-xl shadow-lg">
                                <div className="space-y-2">
                                    {match.players.map((player, playerIndex) => {
                                        return (
                                            <Player
                                                key={playerIndex}
                                                playerName={player.nickname}
                                                country={player.country}
                                                score={shouldHideScores(groupIndex) ? null : player.score}
                                                category={player.category}
                                                belt={player.belt}
                                                highlighted={player.highlighted}
                                                status={player.status}
                                                t={t}
                                            />
                                        );
                                    })}
                                </div>
                                {match.winner && (
                                    <div className="mt-2 text-center font-semibold text-green-600 dark:text-green-400">
                                        Winner: {match.winner}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export const Tournaments = () => {
    const [selectedTournament, setSelectedTournament] = useState(null);
    const [selectedTournamentData, setSelectedTournamentData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [tournaments, setTournaments] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasTournaments, setHasTournaments] = useState(true);

    const {language} = useLanguage();
    const t = translations[language];

    const storedUserRole = getFromLocalStorage('userPermissions', 'competitor');
    const isAdmin = storedUserRole === 'admin';

    const fetchTournaments = useCallback(async () => {
        const userId = getFromLocalStorage('userId', 0);
        if (!userId) return;
    
        setLoading(true);
    
        try {
            const response = await getTournamentsByUserId(userId);
    
            if (!response || Object.keys(response).length === 0) {
                setHasTournaments(false);
                saveToLocalStorage('tournamentsUser', []);
                setTournaments({});
                setSelectedTournament('');
                setSelectedCategory('');
                return;
            }
    
            setHasTournaments(true);
    
            if (isAdmin) {
                setTournaments(response);
    
                const tournamentIds = Object.keys(response);
                const lastTournamentId = tournamentIds[tournamentIds.length - 1];
                const firstCategory = Object.keys(response[lastTournamentId].tournament_categories)[0];
    
                setSelectedTournament(lastTournamentId);
                saveToLocalStorage('bracketSelectedTournament', lastTournamentId);
    
                setSelectedCategory(firstCategory);
            } else {
                setTournaments(response);
                const tournamentIds = Object.keys(response);
                const lastTournamentId = tournamentIds[tournamentIds.length - 1];
                setSelectedTournament(lastTournamentId);
                setSelectedCategory(lastTournamentId);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [isAdmin]);

    const fetchDraw = useCallback(async () => {
        if (!selectedTournament) return;

        setLoading(true);
        try {
            const drawId = isAdmin ? selectedCategory : selectedTournament;
            const drawData = await getTournamentDrawById(drawId);
            setSelectedTournamentData(drawData);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [selectedTournament, selectedCategory, isAdmin]);

    useEffect(() => {
        fetchTournaments();
    }, [fetchTournaments]);

    useEffect(() => {
        fetchDraw();
    }, [fetchDraw]);

    const handleTournamentSelectChange = useCallback((selectedId) => {
        setSelectedTournament(selectedId);
        setSelectedCategory(selectedId);
        saveToLocalStorage('bracketSelectedTournament', selectedId);
    }, []);


    const handleAdminTournamentSelectChange = useCallback((selectedId) => {
        setSelectedTournament(selectedId);
        setSelectedCategory(Object.keys(tournaments[selectedId].tournament_categories)[0]);
    }, [tournaments]);

    const handleAdminCategorySelectChange = useCallback((selectedId) => {
        setSelectedCategory(selectedId);
    }, []);

    const toggleModal = () => setIsModalOpen(prev => !prev);

    if (loading) {
        return (
            <div className="container mx-auto px-4 py-8 max-w-3xl flex justify-center items-center h-64">
                <ULoading size="lg" color="primary" />
            </div>
        );
    }

    return (
        <div className="flex flex-col h-full">
            <div className="container mx-auto px-4 py-8 max-w-3xl">
                <UCard
                    title={hasTournaments ? (isAdmin ? t.selectTandC : t.selectT) : t.noTournaments}
                >
                    {hasTournaments && (
                        <div className="max-w-xl mx-auto space-y-6">
                            <div className="flex justify-center">
                                <UButton size="sm" onClick={toggleModal}
                                         className="bg-primary hover:bg-primary-dark transition-colors duration-300">
                                    {t.tDetails}
                                </UButton>
                            </div>
                            <div className="space-y-4">
                            {isAdmin ? (
                                    <>
                                        <div className="relative z-20">
                                            <USelect
                                                options={Object.entries(tournaments)
                                                    .sort(([a], [b]) => parseInt(b) - parseInt(a))
                                                    .map(([id, tournament]) => ({
                                                        value: id,
                                                        label: tournament.name
                                                    }))}
                                                value={selectedTournament}
                                                onChange={handleAdminTournamentSelectChange}
                                                placeholder={t.selectT}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="relative z-10">
                                            <USelect
                                                options={Object.entries(tournaments[selectedTournament]?.tournament_categories || {})
                                                    .sort(([a], [b]) => parseInt(b) - parseInt(a))
                                                    .map(([id, name]) => ({
                                                        value: id,
                                                        label: name
                                                    }))}
                                                value={selectedCategory}
                                                onChange={handleAdminCategorySelectChange}
                                                placeholder={t.selectCategory}
                                                className="w-full"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div className="relative z-10">
                                        <USelect
                                            options={Object.entries(tournaments).map(([id, name]) => ({
                                                value: id,
                                                label: name
                                            }))}
                                            value={selectedTournament}
                                            onChange={handleTournamentSelectChange}
                                            placeholder={t.selectT}
                                            className="w-full"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </UCard>
            </div>

            <div className="flex-grow overflow-auto bg-transparent">
                {loading ? (
                    <div className="h-full flex justify-center items-center">
                        <ULoading size="lg" color="primary"/>
                    </div>
                ) : hasTournaments && selectedTournamentData.length === 0 ? (
                    <p className="text-center text-gray-500 mt-8">{t.noGroups}</p>
                ) : hasTournaments && (
                    <TournamentBracket tournamentData={selectedTournamentData}/>
                )}
            </div>

            {isModalOpen && hasTournaments && (
                <TournamentDetails tournament={selectedCategory} closeModal={toggleModal}/>
            )}
        </div>
    );
};
