/**
 * Tomas Costantino
 *
 * Server module for match scores
 */

import axios from "axios";

export async function getMatchesForTournamentIdByUserId(userId, tournamentId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/match/${userId}/${tournamentId}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}

export async function swapMatchParticipants(payload) {
    return axios.post(`${process.env.REACT_APP_API_BASE}/match/swap`, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}

export async function getTournamentMatchesByTournament(tournamentId) {
    return axios.get(`${process.env.REACT_APP_API_BASE}/tournament/${tournamentId}/matches`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        })
}
