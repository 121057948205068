import React, { useState, useEffect } from 'react';
import { UCard } from '../CustomComponents/UCard';
import { ULoading } from "../CustomComponents/ULoading";
import { USelect } from "../CustomComponents/USelect";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import toastr from 'toastr';
import { postUserPhoto } from "../server/user/[...].user.post";
import { patchUser } from "../server/user/[...].user.patch";
import { getKeyData } from "../server/key_data/[...].key_data.get";
import countryFlags from "../utils/CountryFlags";
import { getFromLocalStorage, saveToLocalStorage } from "../utils/localStorageHandler";
import { useLanguage } from "../contexts/LanguageContext";
import { UInput } from "../CustomComponents/UInput";
import { UButton }  from "../CustomComponents/UButton";

const translations = {
    en: {
        editProfile: "Edit Profile",
        name: "Name",
        nickname: "Nickname",
        category: "Category",
        belt: "Belt",
        weight: "Weight",
        dob: "Date of Birth",
        gender: "Gender",
        male: "Male",
        female: "Female",
        nonBinary: "Non-Binary",
        state: "State",
        contactNumber: "Contact Number",
        country: "Country",
        profilePicture: "Profile Picture",
        saveChanges: "Save Changes",
        cancel: "Cancel",
        selectCategory: "Select Category",
        selectBelt: "Select Belt",
        selectCountry: "Select Country",
    },
    pt: {
        editProfile: "Editar Perfil",
        name: "Nome",
        nickname: "Apelido",
        category: "Categoria",
        belt: "Corda",
        weight: "Peso",
        dob: "Data de Nascimento",
        gender: "Gênero",
        male: "Masculino",
        female: "Feminino",
        nonBinary: "Não-Binário",
        state: "Estado",
        contactNumber: "Número de Contato",
        country: "País",
        profilePicture: "Foto de Perfil",
        saveChanges: "Salvar Alterações",
        cancel: "Cancelar",
        selectCategory: "Selecione a Categoria",
        selectBelt: "Selecione a Corda",
        selectCountry: "Selecione o País",
    }
};

export const EditProfile = ({ user, onClose, onUpdate }) => {
    const [editedUser, setEditedUser] = useState(user);
    const [keyData, setKeyData] = useState(getFromLocalStorage('keyData', {'categories': [], 'belts': [], 'categories_with_belt': [], 'gender': []}));
    const [changedFields, setChangedFields] = useState({});
    const [photo, setPhoto] = useState(null);
    const [loading, setLoading] = useState(false);
    const { language } = useLanguage();
    const t = translations[language];

    const isAdmin = getFromLocalStorage('userPermissions', false) === 'admin';

    const countryOptions = Object.keys(countryFlags).map(key => ({
        value: key,
        label: `${countryFlags[key]} ${key}`,
    }));

    useEffect(() => {
        const fetchKeyData = async () => {
            setLoading(true);
            try {
                const response = await getKeyData();
                setKeyData(response);
                saveToLocalStorage('keyData', response);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchKeyData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedUser(prev => ({ ...prev, [name]: value }));
        setChangedFields(prev => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (name, value) => {
        setEditedUser(prev => ({ ...prev, [name]: value }));
        setChangedFields(prev => ({ ...prev, [name]: value }));
    };

    const handlePhoneChange = (value) => {
        setEditedUser(prev => ({ ...prev, phone: value }));
        setChangedFields(prev => ({ ...prev, phone: value }));
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setPhoto(e.target.files[0]);
        }
    };

    const submitChanges = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (photo) {
                const photoFormData = new FormData();
                photoFormData.append('user_id', editedUser.id);
                photoFormData.append('photo', photo);
                const photoResponse = await postUserPhoto(photoFormData);

                if (photoResponse) {
                    toastr.success('Photo uploaded successfully');
                    editedUser.imageUrl = photoResponse.imageUrl;
                } else {
                    toastr.error('Could not upload photo, but proceeding with other changes');
                }
            }

            if (Object.keys(changedFields).length > 0) {
                const response = await patchUser(editedUser.id, changedFields);
                if (response) {
                    const updatedUser = { ...editedUser, ...changedFields };

                    if (onUpdate) {
                        onUpdate(updatedUser);
                    }

                    if (getFromLocalStorage('userId', null) === editedUser.id) {
                        saveToLocalStorage(`userProfile_${editedUser.id}`, updatedUser);
                    }
                    toastr.success('Profile updated successfully');
                }
            } else if (!photo) {
                toastr.info("No changes made");
            }
            onClose();
        } catch (error) {
            toastr.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <UCard
                title={t.editProfile}
                bodyClass="space-y-4 max-h-[80vh] overflow-y-auto"
                className="w-full max-w-2xl"
                footer={
                <div className="flex justify-end space-x-2">
                    <button type="button" onClick={onClose} disabled={loading}
                            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                        {t.cancel}
                    </button>
                    <UButton onClick={submitChanges} disabled={loading}
                             className="px-4 py-2 border border-transparent rounded-md shadow-sm text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                        {t.saveChanges}
                    </UButton>
                </div>
            }
            >
                <form onSubmit={submitChanges} className="space-y-4">
                    {isAdmin && (
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.nickname}</label>
                            <UInput type="text" name="nickname" value={editedUser.nickname}
                                    onChange={handleInputChange}/>
                        </div>
                    )}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.name}</label>
                        <UInput type="text" name="name" value={editedUser.name} onChange={handleInputChange}/>
                    </div>
                    <div>
                        <label
                            className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.contactNumber}</label>
                        <PhoneInput
                            country={'au'}
                            value={editedUser.phone}
                            onChange={handlePhoneChange}
                            inputClass="w-full p-2 mt-1 block rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                        />
                    </div>
                    <div>
                        <label
                            className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.category}</label>
                        <USelect
                            options={keyData.categories.map(category => ({
                                value: category.id,
                                label: category.category
                            }))}
                            value={editedUser.category_id}
                            onChange={(value) => handleSelectChange('category_id', value)}
                            placeholder={t.selectCategory}
                        />
                    </div>
                    {keyData.categories_with_belt.some(category => category.category_id === parseInt(editedUser.category_id)) && (
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.belt}</label>
                            <USelect value={editedUser.belt_id}
                                     options={keyData.belts.map(belt => ({
                                         value: belt.id,
                                         label: belt.belt
                                     }))}
                                     onChange={(value) => handleSelectChange('belt_id', value)}
                                     className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50">
                            </USelect>
                        </div>
                    )}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.weight}</label>
                        <UInput type="number" name="weight" value={editedUser.weight} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.dob}</label>
                        <UInput type="date" name="dob" value={editedUser.dob} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.gender}</label>
                        <USelect name="gender_id" value={editedUser.gender_id}
                                 options={keyData.gender.map(gender => ({
                                     value: gender.id,
                                     label: gender.gender
                                 }))}
                                 onChange={(value) => handleSelectChange('gender_id', value)}
                                 className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50">
                        </USelect>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.state}</label>
                        <UInput type="text" name="state" value={editedUser.state} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label
                            className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.country}</label>
                        <USelect
                            options={countryOptions}
                            value={editedUser.country}
                            onChange={(value) => handleSelectChange('country', value)}
                            placeholder={t.selectCountry}
                        />
                    </div>
                    <div>
                        <label
                            className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t.profilePicture}</label>
                        <UInput type="file" onChange={handleImageChange}
                               className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-primary file:text-white hover:file:bg-primary-dark"/>
                    </div>
                    {loading && <ULoading />}
                </form>
            </UCard>
        </div>
    );
};
