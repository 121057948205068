/*
 * Tomas Costantino
 *
 * Retrieve cateogries belts and key_data with belt
 * */

import axios from "axios";


export async function getKeyData() {
    return axios.get(`${process.env.REACT_APP_API_BASE}/key-data`)
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            throw new Error(error.response.data.detail);
        })
}
