import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

export const USelect = ({ options, value, onChange, placeholder = 'Select an option' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
        options.find(option => option.value === value) || null
    );
    const [dropdownStyle, setDropdownStyle] = useState({});
    const selectRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target) &&
                dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        const updateDropdownPosition = () => {
            if (selectRef.current && isOpen) {
                const selectRect = selectRef.current.getBoundingClientRect();
                setDropdownStyle({
                    position: 'fixed',
                    top: `${selectRect.bottom}px`,
                    left: `${selectRect.left}px`,
                    width: `${selectRect.width}px`,
                    zIndex: 1000,
                });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', updateDropdownPosition);
        window.addEventListener('resize', updateDropdownPosition);

        updateDropdownPosition();

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('scroll', updateDropdownPosition);
            window.removeEventListener('resize', updateDropdownPosition);
        };
    }, [isOpen]);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        onChange(option.value);
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const renderDropdown = () => {
        return ReactDOM.createPortal(
            <ul
                ref={dropdownRef}
                className="bg-background-light dark:bg-darkMode-light shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                style={dropdownStyle}
            >
                {options.map((option) => (
                    <li
                        key={option.value}
                        className={`${
                            option.value === selectedOption?.value
                                ? 'text-text-DEFAULT bg-primary-DEFAULT dark:bg-primary-dark'
                                : 'text-text-DEFAULT dark:text-text-dark'
                        } cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-primary-light dark:hover:bg-primary-DEFAULT`}
                        onClick={() => handleOptionClick(option)}
                    >
                        <span className={`block ${option.value === selectedOption?.value ? 'font-semibold' : 'font-normal'}`}>
                            {option.label}
                        </span>
                        {option.value === selectedOption?.value && (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary-DEFAULT dark:text-text-dark">
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                            </span>
                        )}
                    </li>
                ))}
            </ul>,
            document.body
        );
    };

    return (
        <div className="relative" ref={selectRef}>
            <div
                className="w-full px-4 py-2 text-left bg-background-light dark:bg-darkMode-light border border-secondary-DEFAULT dark:border-darkMode-lighter rounded-md shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-primary-DEFAULT focus:border-primary-DEFAULT"
                onClick={toggleDropdown}
            >
                <span className={`block truncate ${selectedOption ? 'text-text-DEFAULT dark:text-text-dark' : 'text-text-light dark:text-text-dark'}`}>
                    {selectedOption ? selectedOption.label : placeholder}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg className="h-5 w-5 text-text-light dark:text-text-dark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </span>
            </div>
            {isOpen && renderDropdown()}
        </div>
    );
};
