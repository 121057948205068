/*
 * Tomas Costantino
 *
 * Handle authentication with server
 */

import axios from "axios";

export async function postLogin(payload) {
    return axios.post(`${process.env.REACT_APP_API_BASE}/login`, payload)
        .then(response => {
            return response;
        })
        .catch(error => {
            localStorage.clear();
            throw new Error(error.response.data.detail);
        });
}