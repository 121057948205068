import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Navbar } from "../UIComponents/Navbar";
import { Footer } from "../UIComponents/Footer";
import { getFromLocalStorage } from "../utils/localStorageHandler";

export const AuthenticatedLayout = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(getFromLocalStorage('authToken', false));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsAuthenticated(!!isAuthenticated);
        setIsLoading(false);
    }, [isAuthenticated]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    return (
        <div className="flex flex-col min-h-screen bg-background-DEFAULT dark:bg-darkMode-DEFAULT">
            <Navbar />
            <main className="flex-grow overflow-hidden">
                <div className="h-full overflow-y-auto">
                    <Outlet />
                </div>
            </main>
            <Footer className="fixed bottom-0 left-0 right-0" />
        </div>
    );
};