/**
 * Tomas Costantino
 *
 * Deactivate user
 */

import axios from "axios";

export async function deleteUser(userId) {
    return axios.delete(`${process.env.REACT_APP_API_BASE}/user/${userId}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response.data.detail);
        });
}