import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { UCard } from '../CustomComponents/UCard';
import { USelect } from '../CustomComponents/USelect';
import { UButton } from "../CustomComponents/UButton";
import { ULoading } from '../CustomComponents/ULoading';
import { getTournamentsForAdmin, getTournamentDrawForAdmin } from "../server/tournament/[...].tournament.get";
import { updateTournamentStatus, updateTournamentDraw } from "../server/tournament/[...].tournament.patch";
import { confirmEnrollToTournament } from "../server/tournament/[...].tournament.post";
import countryFlags from "../utils/CountryFlags";
import { categoryColors } from "../utils/categoryColors";
import { beltColors } from "../utils/beltColors";
import { useLanguage } from "../contexts/LanguageContext";
import toastr from "toastr";

const translations = {
    en: {
        toPlay: "Participants to play.",
        selectC: "Select category",
        selectT: "Select tournament",
        nQualifiers: "Number of qualifiers",
        moveAllPlayers: 'Move all players',
        availablePlayers: "Available players",
        availableJudges: "Available Judges",
        assignedJudges: "Assigned Judges",
        injured: "Injured",
        disqualified: "Disqualified",
        noShow: "No show",
        startTournament: "Start Tournament",
        finishTournament: "Finish Tournament",
        finishedTournament: "Tournament Finished",
        saveChanges: "Save Changes",
        title: "Tournament draws management",
        final: "Final",
        semi: "Semifinal",
        quarter: "Quarter Final",
        qualys: "Qualifiers",
    },
    pt: {
        toPlay: "Participantes para jogar.",
        selectC: "Selecionar categoria",
        selectT: "Selecionar torneio",
        nQualifiers: "Número de qualificadores",
        moveAllPlayers: 'Mover todos os jogadores',
        availablePlayers: "Jogadores disponíveis",
        availableJudges: 'Juízes disponíveis',
        assignedJudges: "Juízes Designados",
        injured: "Injured",
        disqualified: "Disqualified",
        noShow: "No show",
        startTournament: "Iniciar torneio",
        finishTournament: "Terminar torneio",
        finishedTournament: "Torneio concluído",
        saveChanges: "Salvar alterações",
        title: "Gerenciamento de sorteios de torneios",
        final: "Final",
        semi: "Semifinal",
        quarter: "Quartas de final",
        qualys: "Eliminatórias",
    }
};

function capitalizeFirstLetter(string) {
    return string.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const Player = ({ player, onDragStart, onTouchStart, groupId, draggable = true, onStatusChange }) => {
    const { nickname: playerName, country, score, category_id, belt_id, highlighted, status } = player;

    const getFlagEmoji = (countryName) => {
        const capitalizedCountryName = capitalizeFirstLetter(countryName);
        return countryFlags[capitalizedCountryName] || '';
    };

    const getCategoryColor = (categoryId) => {
        return categoryColors[categoryId] || '';
    }

    const getBeltColor = (beltId) => {
        return beltColors[beltId] || '';
    }

    const getStatusStyle = () => {
        switch (status) {
            case 'injured':
                return 'bg-yellow-200 dark:bg-yellow-800';
            case 'disqualified':
                return 'bg-red-400 dark:bg-red-800';
            case 'no show':
                return 'bg-red-400 dark:bg-red-800';
            default:
                return '';
        }
    }

    const statusOptions = [
        { value: 'competing', label: 'Active .' },
        { value: 'injured', label: 'Injured  .' },
        { value: 'disqualified', label: 'Disqualified  .' },
        { value: 'no show', label: 'No Show  .' },
    ];

    return (
        <div
            className={`bg-gray-100 border dark:border-none dark:bg-darkMode-lighter text-black dark:text-white w-full h-16 rounded-xl p-4 flex items-center justify-between ${getStatusStyle()} ${
                !draggable ? 'opacity-80 cursor-not-allowed' : ''
            }`}
            draggable={draggable}
            onDragStart={draggable ? (e) => onDragStart(e, player, groupId || 'available') : null}
            onTouchStart={draggable ? (e) => onTouchStart(e, player) : null}
        >
            <div className="flex items-start space-x-6 overflow-hidden">
                <span className="text-black dark:text-white font-semibold text-lg break-words">{playerName}</span>
            </div>
            <div className="flex items-center space-x-1 gap-2">
                <div className="w-6 h-10 flex items-center justify-center ml-6">
                    <span>{getFlagEmoji(country)}</span>
                </div>
                {category_id && (
                    <div className="w-12 h-10 flex items-center justify-center">
                        {getCategoryColor(category_id)}
                    </div>
                )}
                {belt_id && (
                    <div className="w-12 h-6 flex items-center justify-center">
                        {getBeltColor(belt_id)}
                    </div>
                )}
                {groupId && (
                    <>
                        {highlighted && <div className="text-yellow-500 text-xl">⭐️</div>}
                        {score && (
                            <div className="bg-darkMode-lighter text-white rounded-lg w-12 h-12 flex items-center justify-center font-bold text-xl">
                                {score}
                            </div>
                        )}
                        <div className="relative">
                            <USelect
                                options={statusOptions}
                                value={status || ''}
                                onChange={(value) => onStatusChange(player, value)}
                                placeholder="✅   ."
                                className="absolute opacity-0 inset-0 p-2 w-full h-full cursor-pointer"
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const Participants = ({ participants, tournamentStatus, onPlayerAssign, handleDragStart, onTouchStart }) => {
    const { language } = useLanguage();
    const t = translations[language];

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const onDrop = (e) => {
        let playerData = JSON.parse(e.dataTransfer.getData("playerData"));
        onPlayerAssign(playerData);
    };

    return (
        <div
            className="participants"
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            <h3 className="text-lg font-semibold mb-2">
                {t.toPlay}
            </h3>
            <div className="participants-grid grid grid-cols-4 gap-4">
                {participants.map((player, index) => (
                    <Player
                        key={`participant_${player.user_id}_${index}`}
                        player={player}
                        onDragStart={handleDragStart}
                        onTouchStart={onTouchStart}
                    />
                ))}
            </div>
        </div>
    );
}

const Sidebar = ({
                     isCollapsed,
                     onToggle,
                     tournaments,
                     selectedTournament,
                     onTournamentChange,
                     selectedCategory,
                     onCategoryChange,
                     onStartEndTournament,
                     onSaveChanges,
                     hasUnsavedChanges,
                     availablePlayers,
                     categoryJudges,
                     allJudges,
                     onJudgeAssign,
                     onJudgeDeassign,
                     handleDragStart,
                     onTouchStart,
                     onPlayerDrop,
                     isLoading,
                     qualifiers,
                     onQualifiersChange,
                     participants,
                     handlePlayerAssignAll,
                     userRequests,
                     enrollPlayer
                 }) => {
    const [isPlayersVisible, setIsPlayersVisible] = useState(true);
    const [isSelectedJudgesVisible, setIsSelectedJudgesVisible] = useState(true);
    const [isAvailableJudgesVisible, setIsAvailableJudgesVisible] = useState(true);

    const selectedTournamentData = tournaments.find(t => t.tournament_id === selectedTournament);
    const selectedCategoryData = selectedTournamentData?.categories.find(c => c.category_id === selectedCategory);
    const tournamentStatus = selectedCategoryData?.status;

    const qualifierOptions = useMemo(() => {
        const options = [4];
        let nextOption = 8;
        while (nextOption <= participants.length) {
            options.push(nextOption);
            nextOption *= 2;
        }
        return options;
    }, [participants]);

    const { language } = useLanguage();
    const t = translations[language];

    const renderJudgeBox = (judges, onAction, isAssigned) => (
        <div className="mb-4 bg-white dark:bg-gray-700 p-4 rounded-lg shadow">
            <div className="space-y-2">
                {judges.map((judge) => (
                    <div key={judge.user_id} className="flex items-center justify-between">
                        <span>{judge.nickname}</span>
                        <button
                            onClick={() => onAction(judge)}
                            className={`px-2 py-1 rounded ${
                                isAssigned
                                    ? 'bg-red-500 hover:bg-red-600 text-white'
                                    : 'bg-green-500 hover:bg-green-600 text-white'
                            }`}
                        >
                            {isAssigned ? 'Remove' : 'Add'}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const onDrop = (e) => {
        e.preventDefault();
        const playerData = JSON.parse(e.dataTransfer.getData("playerData"));
        onPlayerDrop(playerData);
    };

    if (isLoading) {
        return null;
    }

    return (
        <div
            className={`bg-gray-100 dark:bg-gray-800 transition-all duration-300 ${
                isCollapsed ? 'w-16' : 'w-[310px]'
            } flex flex-col fixed top-18 left-0 z-40 h-[calc(100vh-4rem)]`}
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            <button
                onClick={onToggle}
                className="p-4 w-full text-left text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
            >
                {isCollapsed ? '>' : '<'}
            </button>
            {!isCollapsed && (
                <div className="flex flex-col h-full overflow-hidden">
                    <div className="p-4 flex-grow overflow-y-auto">
                        <div className="mb-4">
                            <label htmlFor="tournament-select"
                                   className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                {t.selectT}
                            </label>
                            <USelect
                                options={tournaments.map(t => ({
                                    value: t.tournament_id.toString(),
                                    label: t.tournament_name
                                }))}
                                value={selectedTournament ? selectedTournament.toString() : ''}
                                onChange={onTournamentChange}
                                placeholder={t.selectT}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="category-select"
                                   className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                {t.selectC}
                            </label>
                            <USelect
                                options={selectedTournamentData?.categories.map(c => ({
                                    value: c.category_id.toString(),
                                    label: c.category_name
                                })) || []}
                                value={selectedCategory ? selectedCategory.toString() : ''}
                                onChange={onCategoryChange}
                                placeholder={t.selectC}
                                isDisabled={!selectedTournament}
                            />
                        </div>
                        {tournamentStatus === 'not started' && participants.length > 0 && (
                            <div className="mb-4">
                                <label htmlFor="qualifiers-select"
                                       className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                    {t.nQualifiers}
                                </label>
                                <USelect
                                    options={qualifierOptions.map(n => ({
                                        value: n.toString(),
                                        label: n.toString()
                                    }))}
                                    value={qualifiers ? qualifiers.toString() : '4'}
                                    onChange={(value) => onQualifiersChange(parseInt(value))}
                                    placeholder="Select number of qualifiers"
                                    isDisabled={!selectedTournament || !selectedCategory}
                                />
                            </div>
                        )}
                        {tournamentStatus === 'not started' && (
                            <div className="mb-4">
                                <UButton className='bg-primary w-full' onClick={handlePlayerAssignAll}>
                                    {t.moveAllPlayers}
                                </UButton>
                            </div>
                        )}
                        <div className="mb-4">
                            <h3
                                onClick={() => setIsPlayersVisible(!isPlayersVisible)}
                                className="text-lg font-semibold cursor-pointer flex items-center"
                            >
                                {t.availablePlayers}
                                <span className="ml-2">{isPlayersVisible ? '▼' : '▶'}</span>
                            </h3>
                            {isPlayersVisible && (
                                <div className="sidebar-section-participants-list mt-2 space-y-2">
                                    {availablePlayers.map((player, index) => (
                                        <Player
                                            key={`available_${player.user_id}_${index}`}
                                            player={player}
                                            onDragStart={handleDragStart}
                                            onTouchStart={onTouchStart}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>

                        {userRequests?.length > 0 && (
                            <div className="mb-4">
                                <h3 className="text-lg font-semibold cursor-pointer flex items-center">
                                    Users that requested enroll
                                </h3>
                                <div className="sidebar-section-participants-list mt-2 space-y-2">
                                    {userRequests.map((user, index) => (
                                        <div key={index} className='bg-gray-100 border dark:border-none dark:bg-darkMode-lighter text-black dark:text-white w-full h-16 rounded-xl p-4 flex items-center justify-between'>
                                            <div className="flex items-start space-x-6 overflow-hidden">
                                                <span
                                                    className="text-black dark:text-white font-semibold text-lg break-words">{user.nickname}
                                                </span>
                                            </div>
                                            <div>
                                                <button className='px-2 py-1 rounded bg-green-500 hover:bg-green-700 text-white' onClick={(e) => {e.preventDefault(); enrollPlayer(user);}}>Accept</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {categoryJudges.length > 0 && (
                            <div className="mb-4">
                                <h3
                                    onClick={() => setIsSelectedJudgesVisible(!isSelectedJudgesVisible)}
                                    className="text-lg font-semibold cursor-pointer flex items-center"
                                >
                                    {t.assignedJudges}
                                    <span className="ml-2">{isSelectedJudgesVisible ? '▼' : '▶'}</span>
                                </h3>
                                {isSelectedJudgesVisible && (
                                    <div className="sidebar-section-participants-list mt-2 space-y-2">
                                        {renderJudgeBox(categoryJudges, onJudgeDeassign, true)}
                                    </div>
                                )}
                            </div>
                        )}
                        {allJudges.length > 0 && (
                            <div className="mb-4">
                                <h3
                                    onClick={() => setIsAvailableJudgesVisible(!isAvailableJudgesVisible)}
                                    className="text-lg font-semibold cursor-pointer flex items-center"
                                >
                                    {t.availableJudges}
                                    <span className="ml-2">{isAvailableJudgesVisible ? '▼' : '▶'}</span>
                                </h3>
                                {isAvailableJudgesVisible && (
                                    <div className="sidebar-section-participants-list mt-2 space-y-2">
                                        {renderJudgeBox(allJudges, onJudgeAssign, false)}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="p-4 border-t border-gray-200 dark:border-gray-700 space-y-2">
                        {selectedTournament && selectedCategory && (
                            <button
                                onClick={onStartEndTournament}
                                className={`w-full py-2 px-4 rounded-md ${
                                    tournamentStatus === 'not started'
                                        ? 'bg-green-500 hover:bg-green-600 text-white'
                                        : tournamentStatus === 'ongoing'
                                            ? 'bg-red-500 hover:bg-red-600 text-white'
                                            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                }`}
                                disabled={tournamentStatus === 'finished'}
                            >
                                {tournamentStatus === 'not started'
                                    ? `${t.startTournament}`
                                    : tournamentStatus === 'ongoing'
                                        ? `${t.finishTournament}`
                                        : `${t.finishedTournament}`}
                            </button>
                        )}
                        <button
                            onClick={onSaveChanges}
                            className={`w-full py-2 px-4 rounded-md ${
                                hasUnsavedChanges
                                    ? 'bg-blue-500 hover:bg-blue-600 text-white'
                                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            }`}
                            disabled={!hasUnsavedChanges}
                        >
                            {t.saveChanges}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

const TournamentBracket = ({ groups, onGroupChange, handleDragStart }) => {
    const { language } = useLanguage();
    const t = translations[language];

    const determineRoundName = useCallback((round, roundIndex) => {
        if (round.round === 0) return t.qualys;

        const totalPlayers = round.matches.reduce((sum, match) => sum + match.players.length, 0);

        switch (totalPlayers) {
            case 4: return t.final;
            case 8: return t.semi;
            case 16: return t.quarter;
            default: return `Round ${roundIndex + 1}`;
        }
    }, [t.final, t.qualys, t.quarter, t.semi]);

    const handlePlayerDrop = (e, groupIndex, matchIndex, playerIndex) => {
        e.preventDefault();
        const incomingPlayer = JSON.parse(e.dataTransfer.getData("playerData"));

        if (incomingPlayer.status !== 'competing') {
            toastr.error('Cannot assign a player that cannot participate');
            return;
        }

        const sourceGroup = e.dataTransfer.getData("currentGroup");

        const updatedGroups = JSON.parse(JSON.stringify(groups));

        const isSameGroup = sourceGroup === `group_${groupIndex}`;

        if (!isSameGroup) {
            const isPlayerInGroup = updatedGroups[groupIndex].matches.some(match =>
                match.players.some(player => player && player.user_id === incomingPlayer.user_id)
            );

            if (isPlayerInGroup) {
                toastr.error("Player is already in this group.");
                return;
            }
        }

        const outgoingPlayer = updatedGroups[groupIndex].matches[matchIndex].players[playerIndex];

        const findAndReplacePlayer = (groups, playerToFind, playerToReplace) => {
            for (let i = 0; i < groups.length; i++) {
                for (let j = 0; j < groups[i].matches.length; j++) {
                    const playerIndex = groups[i].matches[j].players.findIndex(p => p && p.user_id === playerToFind.user_id);
                    if (playerIndex !== -1) {
                        groups[i].matches[j].players[playerIndex] = playerToReplace;
                        return true;
                    }
                }
            }
            return false;
        };

        if (isSameGroup) {
            const sourceMatchIndex = updatedGroups[groupIndex].matches.findIndex(match =>
                match.players.some(player => player && player.user_id === incomingPlayer.user_id)
            );
            const sourcePlayerIndex = updatedGroups[groupIndex].matches[sourceMatchIndex].players.findIndex(
                player => player && player.user_id === incomingPlayer.user_id
            );

            updatedGroups[groupIndex].matches[sourceMatchIndex].players[sourcePlayerIndex] = outgoingPlayer;
            updatedGroups[groupIndex].matches[matchIndex].players[playerIndex] = incomingPlayer;

            onGroupChange(updatedGroups, null, null);
        } else if (sourceGroup === "available") {
            updatedGroups[groupIndex].matches[matchIndex].players[playerIndex] = incomingPlayer;
            onGroupChange(updatedGroups, incomingPlayer, outgoingPlayer);
        } else if (sourceGroup.startsWith("group_")) {
            const sourceGroupIndex = parseInt(sourceGroup.split("_")[1]);

            if (isNaN(sourceGroupIndex) || sourceGroupIndex < 0 || sourceGroupIndex >= updatedGroups.length) {
                console.error('Invalid source group index:', sourceGroupIndex);
                return;
            }

            if (Math.abs(sourceGroupIndex - groupIndex) > 1) {
                toastr.error('The difference between the source and target groups is too long.');
                return;
            }

            updatedGroups[groupIndex].matches[matchIndex].players[playerIndex] = incomingPlayer;
            const swapSuccessful = findAndReplacePlayer(updatedGroups, incomingPlayer, outgoingPlayer);

            if (!swapSuccessful) {
                console.error('Could not find incoming player in source group:', incomingPlayer);
                return;
            }

            onGroupChange(updatedGroups, null, null);
        } else {
            console.error('Unknown source group:', sourceGroup);
            return;
        }
    };

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const handlePlayerStatusChange = (player, status) => {
        const updatedGroups = JSON.parse(JSON.stringify(groups));
        for (let groupIndex = 0; groupIndex < updatedGroups.length; groupIndex++) {
            for (let matchIndex = 0; matchIndex < updatedGroups[groupIndex].matches.length; matchIndex++) {
                const playerIndex = updatedGroups[groupIndex].matches[matchIndex].players.findIndex(p => p && p.user_id === player.user_id);
                if (playerIndex !== -1) {
                    updatedGroups[groupIndex].matches[matchIndex].players[playerIndex] = {
                        ...player,
                        status: status
                    };
                    onGroupChange(updatedGroups, null, null);
                    return;
                }
            }
        }
    };

    return (
        <div className="flex h-full w-full p-4 space-x-8 overflow-auto">
            {groups.map((group, groupIndex) => (
                <div key={groupIndex} className="flex-none">
                    <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
                        {determineRoundName(group, groupIndex)}
                    </h3>
                    <div className="space-y-4">
                        {group.matches.map((match, matchIndex) => (
                            <div key={matchIndex} className="bg-white border dark:border-none dark:bg-darkMode p-4 rounded-xl shadow-lg">
                                <div className="space-y-2">
                                    {match.players.map((player, playerIndex) => (
                                        <div
                                            key={`bracket_${groupIndex}_${matchIndex}_${playerIndex}`}
                                            onDragOver={onDragOver}
                                            onDrop={(e) => group.modifiable && handlePlayerDrop(e, groupIndex, matchIndex, playerIndex)}
                                        >
                                            <Player
                                                key={`player_${groupIndex}_${matchIndex}_${playerIndex}`}
                                                player={player}
                                                onDragStart={handleDragStart}
                                                onTouchStart={() => {}}
                                                groupId={`group_${groupIndex}`}
                                                draggable={true}
                                                onStatusChange={handlePlayerStatusChange}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export const TournamentDraws = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [availablePlayers, setAvailablePlayers] = useState([]);
    const [categoryJudges, setCategoryJudges] = useState([]);
    const [allJudges, setAllJudges] = useState([]);
    const [tournamentStatus, setTournamentStatus] = useState('');
    const [groups, setGroups] = useState([]);
    const [qualifiers, setQualifiers] = useState(4);
    const [userRequests, setUserRequests] = useState([]);
    const { language } = useLanguage();
    const t = translations[language];

    const fetchTournaments = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await getTournamentsForAdmin();
            setTournaments(response);
            if (response.length > 0) {
                const firstTournament = response[0];
                const firstCategory = firstTournament.categories[0];
                setSelectedTournament(firstTournament.tournament_id);
                setSelectedCategory(firstCategory.category_id);
                setTournamentStatus(firstCategory.status);
            }
        } catch (error) {
            console.error("Failed to fetch tournaments:", error);
            toastr.error("Error fetching tournaments");
        } finally {
            setIsLoading(false);
        }
    }, []);

    const fetchTournamentDraw = useCallback(async () => {
        if (selectedCategory) {
            setIsLoading(true);
            try {
                const tournamentData = await getTournamentDrawForAdmin(selectedCategory);

                if ('groups' in tournamentData) {
                    setGroups(tournamentData['groups']);
                    setParticipants([]);
                }
                if ('participants' in tournamentData) {
                    setParticipants(tournamentData['participants']);
                    setGroups([]);
                    setQualifiers(tournamentData['qualifiers']);
                }
                setAvailablePlayers(tournamentData['available']);
                setCategoryJudges(tournamentData['selectedJudges']);
                setAllJudges(tournamentData['allJudges']);

                setTournamentStatus(tournamentData['status']);
                setUserRequests(tournamentData['user_requests']);

            } catch (error) {
                console.error("Failed to fetch tournament draw:", error);
                toastr.error("Error fetching tournament draw");
            } finally {
                setIsLoading(false);
            }
        }
    }, [selectedCategory]);

    useEffect(() => {
        fetchTournaments();
    }, [fetchTournaments]);

    useEffect(() => {
        if (selectedCategory) {
            fetchTournamentDraw();
        }
    }, [selectedCategory, fetchTournamentDraw]);

    const enrollPlayer = async (user) => {
        setIsLoading(true);
        try {
            const response = await confirmEnrollToTournament(user);
            if (response) {
                toastr.success('Player successfully accepted');
                setTimeout(() => window.location.reload(), 1000);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleTournamentChange = (value) => {
        const tournamentId = parseInt(value, 10);
        setSelectedTournament(tournamentId);
        setSelectedCategory(null);
        const selectedTournamentData = tournaments.find(t => t.tournament_id === tournamentId);
        if (selectedTournamentData && selectedTournamentData.categories.length > 0) {
            setSelectedCategory(selectedTournamentData.categories[0].category_id);
        }
    };

    const handleCategoryChange = (value) => {
        setSelectedCategory(parseInt(value, 10));
    };

    const handleQualifiersChange = (newValue) => {
        setQualifiers(newValue);
        setHasUnsavedChanges(true);
    };

    const handleStartEndTournament = async () => {
        setIsLoading(true);
        try {
            const response = await updateTournamentStatus(selectedCategory);
            if (response.success) {
                setTournamentStatus(response.status);
                toastr.success("Tournament status updated successfully");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                toastr.error(response.error);
            }
        } catch (error) {
            toastr.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSaveChanges = async () => {
        setIsLoading(true);
        try {
            const response = await updateTournamentDraw(selectedCategory, groups, participants, availablePlayers, categoryJudges, qualifiers);
            if (response) {
                setHasUnsavedChanges(false);
                toastr.success("Changes saved successfully");
            }
        } catch (error) {
            console.error("Failed to save changes:", error);
            toastr.error("Error saving changes");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePlayerAssignAll = () => {
        if (availablePlayers.length > 0) {
            setParticipants(prevParticipants => {
                return [...prevParticipants, ...availablePlayers];
            });
            setAvailablePlayers([]);
        }
    }

    const handlePlayerAssign = (player) => {
        const playerExists = participants.some(p => p.user_id === player.user_id);
        if (playerExists) return;
        setParticipants(prev => {
            return [...prev, player];
        });
        setAvailablePlayers(prev => {
            return prev.filter(p => p.user_id !== player.user_id);
        });
        setHasUnsavedChanges(true);
    };

    const handlePlayerDrop = (player) => {
        if (tournamentStatus?.status !== 'not started') {
            toastr.error('Cannot assign as available after tournament started');
            return;
        }
        setAvailablePlayers(prev => {
            if (prev.some(p => p.user_id === player.user_id)) {
                return prev;
            }
            return [...prev, player];
        });

        setParticipants(prev => {
            return prev.filter(p => p.user_id !== player.user_id);
        });

        setHasUnsavedChanges(true);
    };

    const handleDragStart = (e, player, currentGroup) => {
        e.dataTransfer.setData("playerData", JSON.stringify(player));
        e.dataTransfer.setData("currentGroup", currentGroup || "none");
    };

    const onTouchStart = (e) => {
        e.preventDefault();
    };

    const handleJudgeAssign = (judge) => {
        if (tournamentStatus?.status !== 'not started') {
            toastr.error('Cannot modify judges after tournament started');
            return;
        }
        setCategoryJudges(prev => [...prev, judge]);
        setAllJudges(prev => prev.filter(j => j.user_id !== judge.user_id));
        setHasUnsavedChanges(true);
    };

    const handleJudgeDeassign = (judge) => {
        if (tournamentStatus?.status !== 'not started') {
            toastr.error('Cannot modify judges after tournament started');
            return;
        }
        setAllJudges(prev => [...prev, judge]);
        setCategoryJudges(prev => prev.filter(j => j.user_id !== judge.user_id));
        setHasUnsavedChanges(true);
    };

    const handleGroupChange = (updatedGroups, incomingPlayer, outgoingPlayer) => {
    
        if (incomingPlayer && outgoingPlayer) {
            setAvailablePlayers(prevAvailable => {
                const newAvailable = prevAvailable.filter(p => p.user_id !== incomingPlayer.user_id);
                if (!newAvailable.some(p => p.user_id === outgoingPlayer.user_id)) {
                    newAvailable.push(outgoingPlayer);
                }
                return newAvailable;
            });
        }
    
        setGroups(updatedGroups);
        setHasUnsavedChanges(true);
    };

    return (
        <div className="flex">
            <Sidebar
                isCollapsed={isSidebarCollapsed}
                onToggle={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                tournaments={tournaments}
                selectedTournament={selectedTournament}
                onTournamentChange={handleTournamentChange}
                selectedCategory={selectedCategory}
                onCategoryChange={handleCategoryChange}
                onStartEndTournament={handleStartEndTournament}
                onSaveChanges={handleSaveChanges}
                hasUnsavedChanges={hasUnsavedChanges}
                availablePlayers={availablePlayers}
                categoryJudges={categoryJudges}
                allJudges={allJudges}
                onJudgeAssign={handleJudgeAssign}
                onJudgeDeassign={handleJudgeDeassign}
                handleDragStart={handleDragStart}
                onTouchStart={onTouchStart}
                onPlayerDrop={handlePlayerDrop}
                tournamentStatus={tournamentStatus}
                isLoading={isLoading}
                qualifiers={qualifiers}
                onQualifiersChange={handleQualifiersChange}
                participants={participants}
                handlePlayerAssignAll={handlePlayerAssignAll}
                userRequests={userRequests}
                enrollPlayer={enrollPlayer}
            />

            <div className={`flex-1 transition-all duration-300 ${isSidebarCollapsed ? 'ml-16' : 'ml-[320px]'}`}>
                <div className="p-6">
                    <UCard
                        title={t.title}
                        bodyClass="max-h-[calc(100vh-250px)] overflow-y-auto"
                    >
                        {isLoading ? (
                            <ULoading />
                        ) : (
                            <div className="space-y-4">
                                <Participants
                                    participants={participants}
                                    tournamentStatus={tournamentStatus}
                                    onPlayerAssign={handlePlayerAssign}
                                    handleDragStart={handleDragStart}
                                    onTouchStart={onTouchStart}
                                />
                                {groups.length > 0 && (
                                    <TournamentBracket groups={groups} onGroupChange={handleGroupChange} handleDragStart={handleDragStart} />
                                )}
                            </div>
                        )}
                    </UCard>
                </div>
            </div>
        </div>
    );
};
