import React, { useState, useEffect } from 'react';
import { UCard } from '../CustomComponents/UCard';
import { USelect } from '../CustomComponents/USelect';
import { ULoading } from '../CustomComponents/ULoading';
import { UButton } from '../CustomComponents/UButton';
import { getTournamentsForAdmin, getTournamentExportData } from "../server/tournament/[...].tournament.get";

export const ExportData = () => {
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState(null);
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournamentId, setSelectedTournamentId] = useState(null);

    useEffect(() => {
        fetchTournaments();
    }, []);

    const fetchTournaments = async () => {
        try {
            const response = await getTournamentsForAdmin();
            setTournaments(response);
            if (response.length > 0) {
                setSelectedTournamentId(response[0].tournament_id);
            }
        } catch (err) {
            setError('Failed to fetch tournaments. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const fetchExportData = async () => {
        if (!selectedTournamentId) return;

        setDownloading(true);
        setError(null);

        try {
            const blob = await getTournamentExportData(selectedTournamentId);

            // Create download link
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `tournament_${selectedTournamentId}_export.zip`);

            // Trigger download
            document.body.appendChild(link);
            link.click();

            // Cleanup
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (err) {
            console.error('Download error:', err);
            setError('Failed to download data. Please try again later.');
        } finally {
            setDownloading(false);
        }
    };
    
    const handleTournamentChange = (value) => {
        setSelectedTournamentId(Number(value));
        setError(null);
    };

    const tournamentOptions = tournaments.map(t => ({
        value: t.tournament_id.toString(),
        label: t.tournament_name
    }));

    return (
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <UCard title="Export Tournament Data" bodyClass="space-y-4">
                {loading ? (
                    <ULoading />
                ) : error ? (
                    <div className="text-center text-red-500">{error}</div>
                ) : (
                    <>
                        <div className="mb-4">
                            <label htmlFor="tournament-select" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                Select a Tournament
                            </label>
                            <USelect
                                options={tournamentOptions}
                                value={selectedTournamentId ? selectedTournamentId.toString() : ''}
                                onChange={handleTournamentChange}
                                placeholder="Select a Tournament"
                                disabled={downloading}
                            />
                        </div>
                        <UButton
                            onClick={fetchExportData}
                            disabled={!selectedTournamentId || downloading}
                            className="mt-4 w-full bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            {downloading ? 'Downloading...' : 'Download Data'}
                        </UButton>
                    </>
                )}
            </UCard>
        </div>
    );
};
