import React from 'react';
import logo from '../logo.png';

export const Footer = () => {
    return (
        <footer className="mt-auto py-4 bg-background-DEFAULT dark:bg-darkMode-DEFAULT">
            <div className="container mx-auto px-4 flex justify-center items-center">
                <img
                    src={logo}
                    alt="Company Logo"
                    className="h-8 w-auto"
                />
            </div>
        </footer>
    );
};
